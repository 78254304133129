const channelToSubscription = new Map();

let socket = new WebSocket(
  window.location.host === 'localhost:3000'
    ? 'ws://localhost:3001/ws'
    : 'wss://api.dexmap.app/ws'
);
let res = 1000;

socket.onopen = function (e) {
  socket.send('My name is John');
};

socket.onmessage = function (event) {
  const { data: dataStr } = event;
  let data = JSON.parse(dataStr);
  if (!data || data.type !== 'transaction') {
    return;
  }
  const mainData = data;
  const tradeTime = parseInt(mainData.txTime);
  data = data.data;
  const tradePrice = parseFloat(data.marketCap);

  const subscriptionItem = channelToSubscription.get(
    getChannelString(mainData)
  );
  if (!subscriptionItem) {
    return;
  }

  const lastDailyBar = subscriptionItem.lastDailyBar;
  const nextDailyBarTime = getNextBarTime(lastDailyBar.time);

  let bar;
  if (tradeTime >= nextDailyBarTime) {
    bar = {
      time: nextDailyBarTime,
      open: lastDailyBar.close,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
    };
  } else {
    bar = {
      ...lastDailyBar,
      high: Math.max(lastDailyBar.high, tradePrice),
      low: Math.min(lastDailyBar.low, tradePrice),
      close: tradePrice,
    };
  }
  subscriptionItem.lastDailyBar = bar;

  // Send data to every subscriber of that symbol
  subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
};

socket.onclose = function (event) {
  if (event.wasClean) {
    //
  }
  socket = new WebSocket(
    window.location.host === 'localhost:3000'
      ? 'ws://localhost:3001/ws'
      : 'ws://api.demap.app/ws'
  );
};

socket.onerror = function (error) {};

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  res = resolution;
  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(
    getChannelString(symbolInfo)
  );
  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscriberUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(getChannelString(symbolInfo), subscriptionItem);
}

export function unsubscribeFromStream(subscriberUID) {
  // Find a subscription with id === subscriberUID
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // Unsubscribe from the channel if it is the last handler
        // socket.emit('SubRemove', { subs: [channelString] });
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}

function getNextBarTime(barTime) {
  let step = 60;
  if (res == '1') {
    step = 60;
  } else if (res == '15') {
    step = 60 * 15;
  } else if (res == '30') {
    step = 60 * 30;
  } else if (res == '1D') {
    step = 24 * 60 * 60;
  } else if (res == '2D') {
    step = 24 * 60 * 60 * 2;
  }
  return barTime + step * 1000;
}

function getChannelString(symbolInfo) {
  return `0~${symbolInfo.poolAddress || symbolInfo.address}`;
}
