import './chart.css';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import Datafeed from './api';
import { commarize } from '../../../../helpers/MoneyHelpers';

import {
  widget,
  ChartingLibraryWidgetOptions,
  Timezone,
  LanguageCode,
  TradingTerminalFeatureset,
  ThemeName,
} from '../../../../charting_library';

// import {findFirstNonZeroDecimal} from '../../../../helpers/MoneyHelpers'

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
};

const TVChartContainer = () => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { poolAddress } = useParams<{ poolAddress: string }>();

  useEffect(() => {
    const defaultProps: Omit<ChartContainerProps, 'container' | 'interval'> = {
      symbol: poolAddress,
      datafeedUrl: 'https://demo_feed.tradingview.com',
      libraryPath: '/charting_library/',
      chartsStorageUrl: 'https://saveload.tradingview.com',
      chartsStorageApiVersion: '1.1',
      clientId: 'dexmap.app',
      userId: 'dexer',
      // interval: '1',
      fullscreen: false,
      autosize: true,
      studiesOverrides: {},
    };
    const widgetOptions = {
      symbol: defaultProps.symbol as string,
      interval: '2' as ChartingLibraryWidgetOptions['interval'],
      datafeed: Datafeed,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      locale: getLanguageFromURL() || 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'symbol_search_hot_key',
        'compare_symbol_search_spread_operators',
        'header_compare',
        'header_quick_search',
        'header_undo_redo',
        'header_saveload',
      ] as TradingTerminalFeatureset[],
      enabled_features: [
        'study_templates',
        'iframe_loading_compatibility_mode',
      ] as TradingTerminalFeatureset[],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: 'dark' as ThemeName,
      has_intraday: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
      iframe_loading_compatibility_mode: true,
      custom_formatters: {
        priceFormatterFactory: () => {
          return {
            format: (price: number) => {
              if (!price) {
                return price.toString();
              }
              return commarize(price.toString());
            },
          };
        },
      },
      overrides: {
        'paneProperties.background': '#161A25',
        'paneProperties.backgroundType': 'solid',
        'symbolWatermarkProperties.transparency': 90,
        'scalesProperties.textColor': '#AAA',
        'mainSeriesProperties.candleStyle.wickUpColor': '#23AB94', //up price color
        'mainSeriesProperties.candleStyle.wickDownColor': '#F7525F', //down price c
      },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      const priceScale = tvWidget
        .activeChart()
        .getPanes()[0]
        .getMainSourcePriceScale();
      priceScale && priceScale.setAutoScale(true);
    });

    return () => {
      tvWidget.remove();
    };
  }, [poolAddress]);

  return (
    <>
      <div ref={chartContainerRef} className={'tv-advanced-charts'} />
      <Text color="white" fontSize="small" fontWeight="500"></Text>
    </>
  );
};

export default TVChartContainer;
