import React from 'react';
import { ChakraProvider, Box, useMediaQuery } from '@chakra-ui/react';

function UniswapRouter({ poolAddress }: { poolAddress: string }) {
  const [isLargerThan730] = useMediaQuery('(min-width: 1200px)');

  return (
    <ChakraProvider>
      {isLargerThan730 ? (
        <Box
          position="relative"
          width="100%"
          height="60vh"
          bg="#223044"
          padding={'3px'}
        >
          <iframe
            width="100%"
            height="100%"
            allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
            src={`https://flooz.xyz/embed/trade?swapDisabled=false&th&swapToTokenAddress=${poolAddress}&swapLockToToken=false&onRampDisabled=true&onRampNetwork=eth&onRampAsDefault=false&onRampTokenAddress=ETH&network=eth&lightMode=false&backgroundColor=transparent&miniApp=false&miniappIntent=swap`}
          ></iframe>
        </Box>
      ) : (
        <Box
          position="relative"
          width="100%"
          height="60vh"
          bg="#223044"
          padding={'3px'}
        >
          <iframe
            width="100%"
            height="100%"
            allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
            src={`https://flooz.xyz/embed/trade?swapDisabled=false&th&swapToTokenAddress=${poolAddress}&swapLockToToken=false&onRampDisabled=true&onRampNetwork=eth&onRampAsDefault=false&onRampTokenAddress=ETH&network=eth&lightMode=false&backgroundColor=transparent&miniApp=false&miniappIntent=swap`}
          ></iframe>
        </Box>
      )}
    </ChakraProvider>
  );
}

export default UniswapRouter;

// import { CowSwapWidget, CowSwapWidgetParams, TradeType } from '@cowprotocol/widget-react'

// //  Fill this form https://cowprotocol.typeform.com/to/rONXaxHV once you pick your "appCode"
// const params: CowSwapWidgetParams = {
//     "appCode": "My Cool App", // Name of your app (max 50 characters)
//     "width": "100%", // Width in pixels (or 100% to use all available space)
//     "height": "640px",
//     "chainId": 1, // 1 (Mainnet), 100 (Gnosis), 11155111 (Sepolia)
//     "tokenLists": [ // All default enabled token lists. Also see https://tokenlists.org
//         "https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json",
//         "https://tokenlist.aave.eth.link",
//         "https://synths.snx.eth.link",
//         "https://erc20.cmc.eth.link",
//         "https://defi.cmc.eth.link",
//         "https://ipfs.io/ipns/tokens.uniswap.org",
//         "https://files.cow.fi/tokens/CoinGecko.json",
//         "https://files.cow.fi/tokens/CowSwap.json",
//         "https://www.gemini.com/uniswap/manifest.json",
//         "https://messari.io/tokenlist/messari-verified",
//         "https://static.optimism.io/optimism.tokenlist.json",
//         "https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json"
//     ],
//     "tradeType": TradeType.SWAP, // TradeType.SWAP, TradeType.LIMIT or TradeType.ADVANCED
//     "sell": { // Sell token. Optionally add amount for sell orders
//         "asset": "USDC",
//         "amount": "100000"
//     },
//     "buy": { // Buy token. Optionally add amount for buy orders
//         "asset": "COW",
//         "amount": "0"
//     },
//     "enabledTradeTypes": [ // TradeType.SWAP, TradeType.LIMIT and/or TradeType.ADVANCED
//         TradeType.SWAP
//     ],
//     "theme": { // light/dark or provide your own color palette
//         "baseTheme": "dark",
//         "primary": "#b8daff",
//         "paper": "#222f44",
//         "text": "#152036"
//     },
//     "standaloneMode": false,
//     "disableToastMessages": false,
//     "disableProgressBar": false,
//     "images": {},
//     "sounds": {},
//     "customTokens": []
// }

// // Ethereum EIP-1193 provider. For a quick test, you can pass `window.ethereum`, but consider using something like https://web3modal.com
// const provider = window.ethereum

// function App() {
//   return (
//     <CowSwapWidget params={params} provider={provider} />
//   )
// }
