import React from 'react';
import { Text, Stack, Flex, Avatar, Box } from '@chakra-ui/react';
import pnLicon from '../../../../assets/pnLicon.svg';
import Card from '../../../../components/Card';
type Props = {
  pnl: number;
  positionValue: number;
  balance: number;
  token: string;
  avgPrice: number;
  investUsd: number;
  investEth: number;
  holderRank: number;
};
const PoolCard = ({
  pnl,
  positionValue,
  balance,
  token,
  avgPrice,
  investUsd,
  investEth,
  holderRank,
}: Props) => {
  return (
    <Card
      title={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Text as="span" fontSize="lg" fontWeight="extrabold" color="#FFFF">
            My Position
          </Text>
          <Box flexGrow={1} />
          <Avatar marginLeft={'5px'} size="sm" src={pnLicon} />
        </Box>
      }
      uncollapsableChildren={
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="span" fontSize="sm" fontWeight="bold" color="#EAEBEF">
              PNL
            </Text>
            <Text
              as="span"
              fontSize="xs"
              fontWeight="semibold"
              color={pnl >= 0 ? '#0CCB82' : '#F08080'}
              filter="blur(2px)"
            >
              ${pnl.toFixed(2)} ({pnl.toFixed(2)}%)
            </Text>
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Text as="span" fontSize="sm" fontWeight="bold" color="#EAEBEF">
              Position Value
            </Text>
            <Text
              as="span"
              fontSize="xs"
              fontWeight="semibold"
              color={positionValue > investUsd ? '#66D9AD' : '#F08080'}
              filter="blur(2px)"
            >
              ${positionValue.toFixed(2)}123
            </Text>
          </Flex>
        </>
      }
      collapsableChildren={
        <Stack spacing="2">
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="span" fontSize="sm" fontWeight="bold" color="#FFFF">
              Amount
            </Text>
            <Text
              as="span"
              fontSize="xs"
              color="#EAEBEF"
              fontWeight="semibold"
              filter="blur(2px)"
            >
              {balance.toFixed(4)} {token}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="span" fontSize="sm" fontWeight="bold" color="#FFFF">
              Average Cost
            </Text>
            <Text
              as="span"
              fontSize="xs"
              color="#EAEBEF"
              fontWeight="semibold"
              filter="blur(2px)"
            >
              ${avgPrice.toFixed(4)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="span" fontSize="sm" fontWeight="bold" color="#FFFF">
              Holder Rank
            </Text>
            <Text
              as="span"
              fontSize="xs"
              color="#EAEBEF"
              fontWeight="semibold"
              filter="blur(2px)"
            >
              #{holderRank}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="span" fontSize="sm" fontWeight="bold" color="#FFFF">
              Invested USD
            </Text>
            <Text
              as="span"
              fontSize="xs"
              color="#EAEBEF"
              fontWeight="semibold"
              filter="blur(2px)"
            >
              ${investUsd.toFixed(2)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="span" fontSize="sm" fontWeight="bold" color="#FFFF">
              Invested ETH
            </Text>
            <Text
              as="span"
              fontSize="xs"
              color="#EAEBEF"
              fontWeight="semibold"
              filter="blur(2px)"
            >
              {investEth.toFixed(2)}
            </Text>
          </Flex>
        </Stack>
      }
    />
  );
};

export default PoolCard;
// Position Value
// Balance SatoshiVM
// Avarage Price
// Invested USD
// Invested ETH
// Holder Rank
