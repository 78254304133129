import React from 'react';
import { Avatar, Box, Flex, Text, SimpleGrid } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import trendLogo from '../../../../assets/TrendLogo.svg';
import { Pool } from '../../../../api/PoolApi';
import { formatUSD } from '../../../../helpers/MoneyHelpers';

type Props = {
  pools: Pool[];
};

export function PoolItem({ pool, index }: { pool: Pool; index: number }) {
  const navigate = useNavigate();
  return (
    <Flex
      alignItems="baseline"
      justifyContent="space-between"
      onClick={() => navigate(`/eth/pool/${pool.poolAddress}`)}
      mb={2}
    >
      <Flex alignItems="baseline" cursor="pointer" gap={2}>
        <Flex alignItems="end" gap={1}>
          <Box
            bg={index == 0 || index == 5 ? '#FE8B03' : ''}
            borderRadius="xl"
            padding="2.5px 5px"
            fontSize="xs"
            color="white"
          >
            #{index + 1}
          </Box>
          <Avatar marginTop="6px" size={'xs'} src={pool.tokenLogo} />
        </Flex>
        <Flex alignItems="flex-end">
          <Text color="white" fontSize="sm" fontWeight="bold">
            {pool.tokenName}
          </Text>
        </Flex>
      </Flex>
      <Flex gap={3}>
        <Box>
          <Text
            fontSize="sm"
            fontWeight="semibold"
            color={parseFloat(pool.changeIn24H) >= 0 ? '#16C671' : '#EA3943'}
          >
            {formatUSD(pool.poolPrice)}
          </Text>
        </Box>
        <Box>
          <Text
            fontSize="sm"
            fontWeight="semibold"
            color={parseFloat(pool.changeIn24H) >= 0 ? '#16C671' : '#EA3943'}
          >
            {parseFloat(pool.changeIn24H).toFixed(2)}%
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}

function Trending({ pools }: Props) {
  return (
    <Box
      bg="#152036"
      borderRadius="lg"
      padding="12px"
      boxShadow="0 0.5rem 1.2rem rgba(82, 85, 92, .15)"
      border="1px #273343 solid"
      w="100%"
      minW="20rem"
    >
      <Flex flexDirection="column">
        <Flex marginTop="0.7rem" alignItems="start" gap={1}>
          <Box>
            <Avatar marginTop="6px" size={'2xs'} src={trendLogo} />
          </Box>
          <Box>
            <Text color="white" fontSize="lg" fontWeight={'semibold'}>
              Trending
            </Text>
          </Box>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} marginTop="32px">
          <Box>
            {pools.slice(0, 5).map((pool, index) => (
              <PoolItem key={pool.poolAddress} pool={pool} index={index} />
            ))}
          </Box>
          <Box>
            {pools.slice(5, 10).map((pool, index) => (
              <PoolItem key={pool.poolAddress} pool={pool} index={index + 5} />
            ))}
          </Box>
        </SimpleGrid>
      </Flex>
    </Box>
  );
}

export default Trending;
