import React from 'react';
import { Grid, GridItem, Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import Positions from './components/positions/Positions';
import TransactionList from './components/transfers/TransferTable';
import TokenInfo from './components/token-info/TokenInfo';
import TokenName from './components/token-name/tokenName';
import TokenPrice from './components/token-price/TokenPrice';
import Chart from './components/chart/Chart';
import Comments from './components/comments/Comments';
import FavList from './components/favorite-list/FavoriteList';
import UniswapRouter from './components/uniswapRouter/UniswapRouter'; // Import UniswapRouter component
import Socials from './components/socials/Socials';
import { PoolInfo } from '../../api/PoolApi';
import CustomTabs from './components/custom-tabs/CustomTabs'; // New import
import WalletTransactions from './components/WalletTransactions';
import { commarize } from '../../helpers/MoneyHelpers';

type Props = {
  viewedPool: PoolInfo | null;
  usdPrice: string;
  setUsdPrice: (val: string) => void;
};

function PoolExplorer({ viewedPool, usdPrice, setUsdPrice }: Props) {
  const isPnLActive = true;

  const [isLargerThan730] = useMediaQuery('(min-width: 730px)');

  // const generalColumnSettings = {
  //   base: 'repeat(1, 1fr)',
  //   sm: 'repeat(1, 1fr)',
  //   md: '20% 62% 17%',
  //   lg: '18% 61% 20%',
  // };

  const gaps = { sm: 1, md: 2, lg: 2 };

  const tokenInfo = viewedPool ? (
    <TokenInfo
      marketCap={String(
        (parseFloat(viewedPool.totalSupply) * parseFloat(usdPrice)) / 10 ** 18
      )}
      pooledWeth={viewedPool.liquidity || '0'}
      totalSupply={viewedPool.totalSupply}
      buyTax={`${viewedPool.buyTax || 0}%`}
      sellTax={`${viewedPool.buyTax || 0}%`}
      liqStatus="Burned"
      tokenAddress={viewedPool.tokenAddress}
      volume={viewedPool.volume}
      banner={viewedPool.banner}
    />
  ) : undefined;

  if (!viewedPool) {
    return null;
  }

  const tokenName = (
    <TokenName
      tokenName={viewedPool.name}
      tokenTicker={viewedPool.ticker}
      otherTicker={viewedPool.otherTicker}
      logo={viewedPool.logo}
      poolAddress={viewedPool.poolAddress}
    />
  );

  const socials = (
    <Socials
      tokenName={viewedPool.name}
      tokenAddress={viewedPool.tokenAddress}
      auditToken={viewedPool.auditStatus}
      twitter={viewedPool.socials.twitter}
      telegram={viewedPool.socials.telegram}
      website={viewedPool.socials.website}
      discord={viewedPool.socials.discord}
      audits={viewedPool.audits || []}
      auditResult={viewedPool.auditResult}
    />
  );

  const tokenPrice = (
    <TokenPrice
      usdPrice={usdPrice}
      change={viewedPool.change}
      contractAddress={viewedPool.tokenAddress || ''}
    />
  );

  return (
    <>
      <title>
        ${viewedPool.ticker} $
        {commarize(
          String(
            (parseFloat(viewedPool.totalSupply) * parseFloat(usdPrice)) /
              10 ** 18
          )
        )}{' '}
        - DexMap
      </title>
      <div style={{ marginTop: '20px' }} />
      <Grid
        templateColumns={{ base: '20% 62% 17%', lg: '18% 61% 20%' }}
        gap={gaps}
      >
        <GridItem>
          {tokenInfo}

          {isPnLActive && (
            <Positions
              pnl={384.12}
              positionValue={224.49}
              balance={132.42312344}
              token={viewedPool.name}
              avgPrice={8.264729859559749}
              investUsd={132}
              investEth={1.02}
              holderRank={324}
            />
          )}
        </GridItem>
        <GridItem>
          <Flex
            minW="inherit"
            justifyContent="space-between"
            alignItems={{ base: 'none', sm: 'center' }}
            flexDirection={{ base: 'column', sm: 'row', md: 'row' }}
          >
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              backgroundColor="#142136"
              borderTopEndRadius={10}
              borderTopStartRadius={10}
            >
              {tokenName}
              {socials}
              {tokenPrice}
            </Box>
          </Flex>

          <Box height="600px">
            <Chart />
          </Box>

          <Box
            bg="#152036"
            borderRadius="lg"
            padding="12px"
            boxShadow="0 0.5rem 1.2rem rgba(82, 85, 92, .15)"
            border="1px #273343 solid"
          >
            <CustomTabs
              tabs={[
                {
                  label: 'Transactions',
                  content: (
                    <TransactionList
                      poolAddress={viewedPool.poolAddress}
                      tokenSymbol={viewedPool.ticker}
                      moneySymbol={viewedPool.otherTicker}
                      setUsdPrice={setUsdPrice}
                    />
                  ),
                },
                {
                  label: 'Comments',
                  content: <Comments poolAddress={viewedPool.poolAddress} />,
                },
                {
                  label: 'Wallet Tx',
                  content: (
                    <WalletTransactions
                      poolAddress={viewedPool.poolAddress}
                      poolPrice={usdPrice}
                    />
                  ),
                },
              ]}
            />
          </Box>
        </GridItem>
        <GridItem>
          <Box pt={isLargerThan730 ? 0 : '7px'}>
            <UniswapRouter poolAddress={viewedPool.tokenAddress} />
          </Box>
          <FavList />
        </GridItem>
      </Grid>
      <Box marginBottom="20px" />
    </>
  );
}

export default observer(PoolExplorer);
