import './styles.css';

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Avatar,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaTelegramPlane, FaGlobe } from 'react-icons/fa';
import { FaX } from 'react-icons/fa6';
import { Pool } from '../../../../api/PoolApi';
import { formatUSD, commarize } from '../../../../helpers/MoneyHelpers';
import { SocialButton } from '../../../pool-explorer/components/socials/Socials';

const TokenCard = ({ pair, isVisible }: { pair: any; isVisible: boolean }) => {
  const navigate = useNavigate();
  const [arrow, setArrow] = useState('');
  const [priceColor, setPriceColor] = useState('#16C671');

  useEffect(() => {
    if (pair.type === undefined) {
      return;
    }

    const isBuy = pair.type === 'buy';
    setArrow(isBuy ? '↗' : '↘');
    setPriceColor(isBuy ? '#16C671' : '#EA3943');
  }, []);

  return (
    <Box
      bg="#152036"
      border="1px #273343 solid"
      borderRadius="8px"
      boxShadow="0 0.5rem 1.2rem rgba(82 85 92 .15)"
      p={4}
      _hover={{ boxShadow: '0 0.5rem 1.2rem rgba(255 255 255 .25)' }}
      cursor="pointer"
      onClick={() => navigate(`/eth/pool/${pair.poolAddress}`)}
      style={isVisible ? { animation: 'slide-in 0.5s ease-out' } : {}}
    >
      <Flex alignItems="center" mb={4} gap={2}>
        <Box>
          <Avatar
            width="32px"
            height="32px"
            name={pair.tokenName}
            src={pair.tokenLogo}
          />
        </Box>
        <Box flex="1">
          <Text fontWeight="bold" fontSize="lg" color={'white'}>
            {pair.tokenName}
          </Text>
          <Text fontWeight="bold" fontSize="sm" color="gray.500">
            MarketCap:
            {pair.marketCap > 0 ? commarize(String(pair.marketCap)) : '$0'}
          </Text>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="end">
          <Text
            as="span"
            fontWeight="bold"
            fontSize="md"
            color={priceColor}
            pb="24px"
          >
            {arrow}
          </Text>
          <Text
            as="span"
            fontWeight="bold"
            fontSize="md"
            color={priceColor}
            pb="24px"
          >
            {formatUSD(pair.poolPrice)}
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <SocialButton
            label="Website"
            href={pair.socials.website}
            disabled={!pair.socials.website}
          >
            <FaGlobe />
          </SocialButton>
          <SocialButton
            label="Telegram"
            href={`https://t.me/${pair.socials.telegram}`}
            disabled={!pair.socials.telegram}
          >
            <FaTelegramPlane />
          </SocialButton>
          <SocialButton
            label="X"
            href={`https://t.me/${pair.socials.twitter}`}
            disabled={!pair.socials.twitter}
          >
            <FaX />
          </SocialButton>
        </Flex>
        <Text fontWeight="bold" fontSize="sm" color="gray.500">
          Replies: {pair.numberOfComments || 0}
        </Text>
      </Flex>
    </Box>
  );
};

const TokenList = ({ list }: { list: any[] }) => {
  return (
    <Box p={4}>
      {/* <Text fontWeight="bold" fontSize="2xl" mb={4} color={'white'}> */}
      {/*   Last Trade */}
      {/* </Text> */}
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing="4">
        {list.slice(0, 20).map((pair, index) => (
          <Box key={`token-list-card-${index}-update-${index}`}>
            {pair && (
              <TokenCard
                isVisible={index == 0}
                key={`${pair.poolAddress}-update-${index}`}
                pair={pair}
              />
            )}
            {!pair && <TradedPairLoding />}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

const TradedPairLoding = () => {
  return (
    <Box
      bg="#152036"
      border="1px #273343 solid"
      borderRadius="8px"
      boxShadow="0 0.5rem 1.2rem rgba(82 85 92 .15)"
      p={4}
    >
      <Flex alignItems="center" mb={4} gap={2}>
        <Box>
          <SkeletonCircle size="10" />
        </Box>
        <Box flex="1">
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="end">
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </Box>
      </Flex>
    </Box>
  );
};

const TradedPairs = ({ tokens }: { tokens: Pool[] | null[] }) => {
  return <TokenList list={tokens} />;
};

export default TradedPairs;
