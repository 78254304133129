import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface CustomTabsProps {
  tabs: Tab[];
}

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box>
      <Flex mb={4}>
        {tabs.map((tab, index) => (
          <Box
            key={index}
            px={4}
            py={2}
            cursor="pointer"
            borderBottom={activeTab === index ? '2px solid #319795' : 'none'}
            onClick={() => setActiveTab(index)}
          >
            <Text
              color={activeTab === index ? '#319795' : 'white'}
              fontWeight={'extrabold'}
              fontSize={'md'}
            >
              {tab.label}
            </Text>
          </Box>
        ))}
      </Flex>
      <Box>{tabs[activeTab].content}</Box>
    </Box>
  );
};

export default CustomTabs;
