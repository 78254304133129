import { axiosAPI } from './BaseApi';

export type Transaction = {
  date: string;
  type: 'buy' | 'sell';
  amountWeth: string;
  amountToken: string;
  amountUsd: string;
  priceUsd: string;
  maker: string;
  etherscanLink: string;
  isFirstSwap?: boolean;
  position: number;
};

export type TransactionTableResponse = {
  swaps: Transaction[];
  numberOfPages: number;
};

export const getTransactions = async (
  poolAddress: string,
  pageNumber: number,
  latestDate: string = '',
  position = 0,
  maker = '0x'
): Promise<TransactionTableResponse | null> => {
  try {
    const res = await axiosAPI.get(
      `/api/pool/${poolAddress}?page=${pageNumber}&latestDate=${latestDate}&position=${position}&maker=${maker}`
    );
    return res.data as TransactionTableResponse;
  } catch {
    return null;
  }
};

type Socials = {
  twitter: string;
  telegram: string;
  website: string;
  email: string;
  discord: string;
};

export type Token = {
  name: string;
  logo: string;
  socials: Socials;
  poolAddress: string;
};

export type Audit = {
  tokenAddress: string;
  auditer: string;
  lastCheckedAt: string;
  isContractVerified: boolean;
  isHoneypot: boolean;
  isMintable: boolean;
  canBlacklist: boolean;
  canModifyTax: boolean;
  buyTax: number;
  sellTax: number;
  isProxyContract: boolean;
  canModifyBalance: boolean;
  canSellAll: boolean;
  isAntiwhale: boolean;
  canWhitelist: boolean;
  creatorAddress: string;
  hasTradingCooldown: boolean;
};

export type AuditResult = {
  isAudited: boolean;
  mediumRisk: number;
  highRisk: number;
};

export type PoolInfo = {
  poolAddress: string;
  tokenAddress: string;
  name: string;
  ticker: string;
  otherTicker: string;
  usdPrice: string;
  tokenPrice: string;
  marketCap: string;
  liquidity: string;
  auditStatus: string;
  logo: string;
  banner: string;
  change: string;
  buyTax: number;
  sellTax: number;
  createdAt: string;
  totalSupply: string;
  volume: string;
  socials: Socials;
  audits: Audit[];
  auditResult: AuditResult;
};

export type Pool = {
  poolAddress: string;
  mainToken: string;
  poolPrice: string;
  liquidity: string;
  volume: string;
  tokenName: string;
  tokenSymbol: string;
  moneyTokenName: string;
  tokenLogo: string;
  numberOfComments: number;
  changeIn24H: string;
  marketCap: string;
  socials: Socials;
};

export const getPoolInfo = async (
  poolAddress: string
): Promise<PoolInfo | 'not_found' | null> => {
  try {
    const res = await axiosAPI.get<undefined, { data: PoolInfo }>(
      `/api/pool_info/${poolAddress}`
    );
    return res.data;
  } catch (e) {
    // @ts-expect-error fix axiosAPI error
    if (e?.response?.status === 404) {
      return 'not_found';
    }
    return null;
  }
};

export const getPools = async (type: string = 'lastTrade'): Promise<Pool[]> => {
  const res = await axiosAPI.get(`/api/pools?type=${type}`);
  return res.data as Pool[];
};

export const getSocialsUpdatedTokens = async (): Promise<Pool[] | null> => {
  try {
    const res = await axiosAPI.get(`/api/pools/social-updated`);
    return res.data as Pool[];
  } catch {
    return null;
  }
};

// comments
export type Comment = {
  commenterAddress: string;
  commentedAt?: string | Date;
  id?: number;
  text: string;
};

export const getCommentsForPool = async (
  poolAddress: string
): Promise<Comment[]> => {
  try {
    const res = await axiosAPI.get(`/api/pool/${poolAddress}/comments`);
    return res.data as Comment[];
  } catch {
    return [];
  }
};

export const createCommentForPool = async (
  poolAddress: string,
  comment: Comment
): Promise<Comment | null> => {
  try {
    const res = await axiosAPI.post(
      `/api/pool/${poolAddress}/comments`,
      comment
    );
    return res.data as Comment;
  } catch {
    return null;
  }
};

export type SearchResult = {
  poolAddress: string;
  tokenLogo: string;
  tokenName: string;
  tokenSymbol: string;
  moneyTokenName: string;
  changeIn24H: string;
  marketCap: string;
  liquidity: string;
  volume: string;
  poolPrice: string;
};

export const searchPools = async (
  searchKeyword: string
): Promise<SearchResult[]> => {
  try {
    const res = await axiosAPI.get(`/api/pools/search/${searchKeyword}`);
    return res.data as SearchResult[];
  } catch (error) {
    console.error('Error searching pools:', error);
    return [];
  }
};

export type WalletTransaction = {
  txHash: string;
  amountOfTokens: string;
  price: string;
  date: string;
  type: 'buy' | 'sell';
};

export const getWalletTransactions = async (
  poolAddress: string
): Promise<WalletTransaction[]> => {
  try {
    const res = await axiosAPI.get(`/api/pool/${poolAddress}/wallet_tx`);
    return res.data as WalletTransaction[];
  } catch (error) {
    console.error('Error fetching wallet transactions:', error);
    throw error;
  }
};

export const getFavoritePools = async (
  poolAddresses: string[]
): Promise<Pool[]> => {
  try {
    const res = await axiosAPI.get('/api/pools/favorites', {
      params: { addresses: poolAddresses.join(',') },
    });
    return res.data as Pool[];
  } catch (error) {
    console.error('Error fetching favorite pools:', error);
    return [];
  }
};
