import React, { FC } from 'react';
import { Box, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import Drawer from './Drawer';
import { IconSearch } from '@tabler/icons-react';

interface AuditProps {
  isOpen: boolean;
  onClose: () => void;
  size: string;
}

const SearchDrawer: FC<AuditProps> = ({ isOpen, onClose, size }) => {
  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      size={size}
      title="Search on DexMap"
    >
      <Box marginTop="30px">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <IconSearch color="white" />
          </InputLeftElement>
          <Input
            bg="#232f44"
            borderRadius="2xl"
            placeholder="Search token address, pair address or name"
            type="search"
            fontSize="sm"
            color="white"
            width="100%"
          />
        </InputGroup>
      </Box>
    </Drawer>
  );
};

export default SearchDrawer;
