import React from 'react';

import Layout from '../../layout/TokenLayout';
import MainPage from './MainPage';

function PoolExplorerPage() {
  return (
    <Layout>
      <MainPage />
    </Layout>
  );
}

export default PoolExplorerPage;
