import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import Layout from '../../layout/TokenLayout';

function Tradingview() {
  return (
    <Layout showTrend>
      <Box marginTop="20px" />
      <Text color="white" margin="20px">
        If you're looking for budget-friendly and cost-effective solutions, you
        might want to explore valuable insights into the best free stock
        screener, aka,{' '}
        <Link href="https://tradingview.com/screener/">TradingView</Link>. Check
        out our step-by-step guide on buying the{' '}
        <Link href="https://www.tradingview.com/symbols/TVC-DXY/">
          Dollar Index
        </Link>{' '}
        and discover the strategies you should follow to diversify your
        investment portfolio. Additionally, gain insights into how to
        effectively track the{' '}
        <Link href="https://www.tradingview.com/economic-calendar/">
          economic calendar
        </Link>{' '}
        and how to leverage forecasting power.
      </Text>
      <Box marginBottom="190px" />
    </Layout>
  );
}
export default observer(Tradingview);
