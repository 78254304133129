import React from 'react';
import { Box, Container, Stack, Text } from '@chakra-ui/react';
import { FaDiscord, FaTelegram, FaTwitter, FaYoutube } from 'react-icons/fa';
import dexmapLogo from '../assets/dexmap.svg';
import { SocialButton } from '../pages/pool-explorer/components/socials/Socials';

export default function SmallWithLogoLeft() {
  return (
    <Box>
      <Box
        // sx={{ position: 'sticky', left: '0' }}
        bg="#152036"
        boxShadow="0 5px 10px rgba(151, 163, 175, 0.05)"
        color="white"
      >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <img src={dexmapLogo} width={'155px'} alt="dexmap Logo" />
          <Text>© 2024 Dexmap. All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton
              label={'Twitter'}
              href={'https://twitter.com/dexmapapp'}
            >
              <FaTwitter />
            </SocialButton>
            <SocialButton label={'Telegram'} href={'https://t.me/dexmapportal'}>
              <FaTelegram />
            </SocialButton>
            <SocialButton
              label={'YouTube'}
              href={'https://www.youtube.com/@DexMap'}
            >
              <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'github.com'}>
              <FaDiscord />
            </SocialButton>
          </Stack>{' '}
          <Text fontSize="sm">
            <a
              href="/legaladvice"
              style={{ textDecoration: 'underline', color: '#D1E7FF' }}
            >
              Legal Advice
            </a>
          </Text>
          <Text fontSize="sm">
            <a
              href="/privacypolicy"
              style={{ textDecoration: 'underline', color: '#D1E7FF' }}
            >
              Privacy Policy
            </a>
          </Text>
          <Text fontSize="sm">
            <a
              href="/tech/tradingview"
              style={{ textDecoration: 'underline', color: '#D1E7FF' }}
            >
              Chart provided by TradingView
            </a>
          </Text>
          <Text fontSize="sm">
            <a
              href="mailto:info@dexmap.app"
              style={{ textDecoration: 'underline', color: '#D1E7FF' }}
            >
              info@dexmap.app
            </a>
          </Text>
        </Container>
      </Box>
    </Box>
  );
}
