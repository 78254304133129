import React from "react"
import Layout from '../../layout/TokenLayout'
import { Box, Heading, Text, Button, Link, VStack, Container } from '@chakra-ui/react'

function Partnership() {
  const handleSignUp = () => {
    window.open('https://forms.gle/1wcWiBxFc9StknP2A', '_blank')
  }
  
  return (
    <Layout>
      <Container maxW="800px" py={8}>
        <VStack spacing={4} align="stretch">
          <Heading as="h1" size="xl" mb={4} color="white">
            DexMap Affiliate Partnership
          </Heading>
          <Text color="white">
            Hello!
          </Text>
          <Text color="white">
            We're excited to offer you a new way to earn with DexMap by sharing token links in your communities, call groups, pages, or anywhere you prefer.
          </Text>
          <Heading as="h2" size="lg" mt={4} mb={2} color="white">
            Here's how it works:
          </Heading>
          <Text color="white">
            If you want to share a token like Neiro (Contract: 0x812ba41e071c7b7fa4ebcfb62df5f45f6fa853ee), you'd normally share this link:
            <br />
            <Link href="https://dexmap.app/eth/pool/0xc555d55279023e732ccd32d812114caf5838fd46" isExternal color="blue.300">
              https://dexmap.app/eth/pool/0xc555d55279023e732ccd32d812114caf5838fd46
            </Link>
          </Text>
          <Text color="white">
            But now, we'll provide you with a unique link that tracks your referrals. Just add ?utm_source={'{yourName}'} at the end.
            <br />
            Example:
            <br />
            <Link href="https://dexmap.app/eth/pool/0xc555d55279023e732ccd32d812114caf5838fd46?utm_source=VenomCalls" isExternal color="blue.300">
              https://dexmap.app/eth/pool/0xc555d55279023e732ccd32d812114caf5838fd46?utm_source=VenomCalls
            </Link>
          </Text>
          <Text color="white">
            For each click on your link, you'll earn $0.1. You can share this link in your call groups, social media pages, or anywhere else!
          </Text>
          <Text color="white">
            Once your earnings reach $20, you'll be able to claim your payment, which will be made exclusively in USDC.
          </Text>
          <Text color="white">
            To get started, simply sign up using the Google Form, and we'll provide you with your custom link. Start sharing and earning today!
          </Text>
          <Text color="white">
            Best regards,
            <br />
            The DexMap Team
          </Text>
          <Box mt={6}>
            <Button colorScheme="blue" onClick={handleSignUp}>
              Sign Up Now
            </Button>
          </Box>
        </VStack>
      </Container>
    </Layout>
  )
}

export default Partnership
