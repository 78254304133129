export function findFirstNonZeroDecimal(str: string) {
  const decimalIndex = str.indexOf('.');
  if (decimalIndex === -1) {
    return -1;
  }
  for (let i = decimalIndex + 1; i < str.length; i++) {
    if (str[i] !== '0') {
      return i - 2;
    }
  }
  return -1;
}

export const commarize = (strNum: string) => {
  const actualNum = parseInt(strNum);
  if (actualNum >= 1e3) {
    const units = ['k', 'M', 'B', 'T'];
    const order = Math.floor(Math.log(actualNum) / Math.log(1000));
    const unitname = units[order - 1];
    const num = (actualNum / 1000 ** order).toFixed(2); // Keep two decimal places
    return num + unitname;
  }

  return parseFloat(strNum).toFixed(2);
};

export const formatUSD = (price: string): string => {
  const numPrice = parseFloat(price);
  if (numPrice >= 0.01) {
    const [integerPart, decimalPart] = numPrice.toFixed(2).split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `$${formattedInteger}.${decimalPart}`;
  }

  const numberOfZeros = findFirstNonZeroDecimal(price);
  if (numberOfZeros === -1) {
    return `$${(parseFloat(price) * 10).toFixed(2).toString()}`;
  }
  const pointMul = numberOfZeros + 4;
  const smallNumber = numberOfZeros
    .toString()
    .split('')
    .map((s) => {
      if (s === '0') {
        return `\u{2081}`;
      }
      if (s === '1') {
        return `\u{2081}`;
      }
      if (s === '2') {
        return `\u{2082}`;
      }
      if (s === '3') {
        return `\u{2083}`;
      }
      if (s === '4') {
        return `\u{2084}`;
      }
      if (s === '5') {
        return `\u{2085}`;
      }
      if (s === '6') {
        return `\u{2086}`;
      }
      if (s === '7') {
        return `\u{2087}`;
      }
      if (s === '8') {
        return `\u{2088}`;
      }
      if (s === '9') {
        return `\u{2089}`;
      }
    })
    .join('');
  return `$0.0${smallNumber}${(parseFloat(price) * 10 ** pointMul).toString().split('.')[0]}`;
};

export function toFixedNumber(strNum: string, toDec: number): string {
  const formatter = new Intl.NumberFormat('en-US');
  return formatter.format(parseFloat(parseFloat(strNum).toFixed(toDec)));
}
