import React, { FC } from 'react';
import { Text, Divider, Box, Flex, useBreakpointValue } from '@chakra-ui/react';

import { Audit as AuditItem } from '../../../../api/PoolApi';
import goplusLogo from '../../../../assets/goplus.png';
import Drawer from '../../../../components/Drawer';
import { shortenAddr } from '../../../../helpers/AddressHelpers';

interface AuditProps {
  isOpen: boolean;
  onClose: () => void;
  size: string;
  tokenName: string;
  audit: AuditItem;
}

const Audit: FC<AuditProps> = ({ isOpen, onClose, size, tokenName, audit }) => {
  const getYesOrNoText = (val?: boolean | null) => {
    if (val === null || val === undefined) {
      return 'N/A';
    }
    return val ? 'Yes' : 'No';
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const AuditRow = ({
    label,
    value,
    color,
  }: {
    label: string;
    value: string;
    color: string;
  }) => (
    <Box>
      <Flex
        direction={isMobile ? 'column' : 'row'}
        justify="space-between"
        py={2}
      >
        <Text fontWeight="medium">{label}</Text>
        <Text color={color}>{value}</Text>
      </Flex>
      <Divider borderColor="#374046" />
    </Box>
  );

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      size={size}
      title={`Audit Report (${tokenName})`}
    >
      <Box fontSize="sm" px={2}>
        <Flex direction="column" align="center" mb={4}>
          <Text fontSize="lg" fontWeight="bold" color="white" mb={2}>
            Alerts
          </Text>
          <img src={goplusLogo} width="120px" alt="audit Logo" />
        </Flex>

        <Box>
          <AuditRow
            label="Contract Verified"
            value={getYesOrNoText(audit.isContractVerified)}
            color={audit.isContractVerified ? '#0BCB82' : '#FFB534'}
          />
          <AuditRow
            label="Honeypot Status"
            value={getYesOrNoText(audit.isHoneypot)}
            color={!audit.isHoneypot ? '#0BCB82' : '#EF5350'}
          />
          <AuditRow
            label="Mintable"
            value={getYesOrNoText(audit.isMintable)}
            color={!audit.isMintable ? '#0BCB82' : '#EF5350'}
          />
          <AuditRow
            label="Blacklisted"
            value={getYesOrNoText(audit.canBlacklist)}
            color={!audit.canBlacklist ? '#0BCB82' : '#FFB534'}
          />
          <AuditRow
            label="Tax Modifiable"
            value={getYesOrNoText(audit.canModifyTax)}
            color={audit.canModifyTax ? '#FFB534' : '#0BCB82'}
          />
          <AuditRow
            label="Buy Tax"
            value={audit.buyTax.toFixed(2)}
            color={audit.buyTax < 20 ? '#0BCB82' : '#FFB534'}
          />
          <AuditRow
            label="Sell Tax"
            value={audit.sellTax.toFixed(2)}
            color={audit.sellTax < 20 ? '#0BCB82' : '#FFB534'}
          />

          <AuditRow
            label="Proxy Contract"
            value={getYesOrNoText(audit.isProxyContract)}
            color={!audit.isProxyContract ? '#0BCB82' : '#FFB534'}
          />
          <AuditRow
            label="Balance Modifiable"
            value={getYesOrNoText(audit.canModifyBalance)}
            color={!audit.canModifyBalance ? '#0BCB82' : '#EF5350'}
          />
          <AuditRow
            label="Sell limit"
            value={getYesOrNoText(!audit.canSellAll)}
            color={audit.canSellAll ? '#0BCB82' : '#FFB534'}
          />
          <AuditRow
            label="Trading Cooldown"
            value={getYesOrNoText(audit.hasTradingCooldown)}
            color={!audit.hasTradingCooldown ? '#0BCB82' : '#EF5350'}
          />
          <AuditRow
            label="Antiwhale"
            value={getYesOrNoText(audit.isAntiwhale)}
            color={!audit.isAntiwhale ? '#0BCB82' : '#FFB534'}
          />
          <AuditRow
            label="Whitelist function"
            value={getYesOrNoText(audit.canWhitelist)}
            color={audit.canWhitelist ? '#FFB534' : '#0BCB82'}
          />
          <AuditRow
            label="Creator Address"
            value={shortenAddr(audit.creatorAddress)}
            color="#0BCB82"
          />
        </Box>

        <Text
          as="p"
          style={{ color: '#485366' }}
          wordBreak="break-word"
          whiteSpace="normal"
          mt={4}
        >
          It is important to note that the DexMap audit results are
          automatically generated by independent third-party entities. While
          these results are provided for informational purposes only, they
          should not be considered as financial advice or a substitute for
          conducting your own due diligence. DexMap does not warrant or
          guarantee the accuracy or completeness of the data obtained through
          these external audits. You are encouraged to consult with multiple
          sources of information before making any investment decisions.
        </Text>
      </Box>
    </Drawer>
  );
};

export default Audit;
