import React from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaTwitter, FaTelegram, FaGlobe, FaDiscord } from 'react-icons/fa';

import update from '../../../../assets/update.svg';
import { SocialButton } from '../../../pool-explorer/components/socials/Socials';
import { Pool } from '../../../../api/PoolApi';

type Props = {
  tokens: Pool[];
};

export function Body({ tokens }: Props) {
  const navigate = useNavigate();
  return (
    <>
      {tokens.slice(0, 5).map((token, index) => (
        <Flex
          key={token.poolAddress}
          onClick={() => navigate(`/eth/pool/${token.poolAddress}`)}
          alignItems="center"
          justifyContent="space-between"
          mb={index !== 4 ? 2 : 0}
        >
          <Flex alignItems="center" cursor="pointer" gap={2}>
            <Avatar size="xs" src={token.tokenLogo} />
            <Text color="white" fontSize="xs" fontWeight="bold">
              {token.tokenName}
            </Text>
          </Flex>
          <Flex gap={1}>
            <SocialButton
              label="Telegram"
              href={`https://t.me/${token.socials.telegram}`}
              disabled={!token.socials.telegram}
            >
              <FaTelegram />
            </SocialButton>
            <SocialButton
              label="Website"
              href={token.socials.website || ''}
              disabled={!token.socials.website}
            >
              <FaGlobe />
            </SocialButton>
            <SocialButton
              label="x"
              href={`https://x.com/${token.socials.twitter}`}
              disabled={!token.socials.twitter}
            >
              <FaTwitter />
            </SocialButton>
            <SocialButton
              label="Discord"
              href={`https://discord.gg/${token.socials.discord}`}
              disabled={!token.socials.discord}
            >
              <FaDiscord />
            </SocialButton>
          </Flex>
        </Flex>
      ))}
    </>
  );
}

function SocialUpdates({ tokens }: Props) {
  return (
    <Box
      bg="#152036"
      borderRadius="lg"
      padding="12px"
      boxShadow="0 0.5rem 1.2rem rgba(82, 85, 92, .15)"
      border="1px #273343 solid"
      w="100%"
      minW="20rem"
    >
      <Flex flexDirection="column">
        <Flex marginTop="0.7rem" alignItems="center" gap={2}>
          <Avatar size="xs" src={update} />
          <Text color="white" fontSize="lg" fontWeight="semibold">
            Updates
          </Text>
        </Flex>
        <Box marginTop="32px">
          <Body tokens={tokens} />
        </Box>
      </Flex>
    </Box>
  );
}

export default SocialUpdates;
