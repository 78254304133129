import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Store } from '../../stores';
import Layout from '../../layout/TokenLayout';
import PoolExplorer from './PoolExplorer';
import MobilePoolExplorer from './MobilePoolExplorer';
import { PoolInfo, getPoolInfo } from '../../api/PoolApi';

function PoolExplorerPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { poolAddress } = useParams<{ poolAddress: string }>();
  const [isMobile, setIsMobile] = useState(false);
  const [viewedPool, setViewedPool] = useState<PoolInfo | null>(null);
  const [usdPrice, setUsdPrice] = useState('0');

  const getPageInfo = async () => {
    if (!poolAddress) {
      return;
    }
    const pool = await getPoolInfo(poolAddress);
    if (pool === 'not_found' || poolAddress.toLowerCase() === 'undefined') {
      return navigate('/');
    }

    if (pool?.poolAddress != poolAddress.toLowerCase()) {
      return navigate(`/eth/pool/${pool?.poolAddress}`);
    }
    setViewedPool(pool);
    setUsdPrice(pool.usdPrice);
    viewedPool &&
      Store.setPageTitle(
        `${viewedPool.ticker} $${
          (parseFloat(viewedPool.totalSupply) * parseFloat(usdPrice)) / 10 ** 18
        } - DexMap`
      );

    ReactGA.send({
      hitType: 'pageview',
      title: `${pool.ticker}/${poolAddress}`,
      page: location.pathname,
    });
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    getPageInfo();
  }, [poolAddress]);

  return (
    <>
      {isMobile ? (
        <MobilePoolExplorer
          viewedPool={viewedPool}
          usdPrice={usdPrice}
          setUsdPrice={setUsdPrice}
        />
      ) : (
        <Layout showTrend>
          <PoolExplorer
            viewedPool={viewedPool}
            setUsdPrice={setUsdPrice}
            usdPrice={usdPrice}
          />
        </Layout>
      )}
    </>
  );
}

export default PoolExplorerPage;
