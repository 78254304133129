import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Flex,
  Avatar,
  useDisclosure,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
} from '@chakra-ui/react';
import { IconSearch } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';

import SearchDrawer from '../components/SearchDrawer';
import dexmapLogo from '../assets/dexmap.svg';
import wDexMap from '../assets/wdexmaplogo.svg';
import gasLogo from '../assets/gasLogo.svg';
import ethereumLogo from '../assets/ethereumLogo.svg';
import { Store } from '../stores';
import LoginButton from '../components/LoginButton';
import SearchPopup from './SearchPopup';

function Navbar() {
  const navigate = useNavigate();
  const { chain } = Store;

  const { isOpen: isOpenSearch, onClose: onCloseSearch } = useDisclosure();

  const {
    isOpen: isOpenSearchPopup,
    onOpen: onOpenSearchPopup,
    onClose: onCloseSearchPopup,
  } = useDisclosure();

  const [isLargerThan920] = useMediaQuery('(min-width: 920px)');
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  useEffect(() => {
    Store.loadChain('eth');
  }, []);

  return (
    <Box bg="#152036" boxShadow="0 5px 10px rgba(151, 163, 175, 0.05)" px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <Box cursor="pointer" onClick={() => navigate('/')} minW="50px">
          <img
            src={isLargerThan600 ? dexmapLogo : wDexMap}
            width={'155px'}
            alt="dexmap Logo"
          />
        </Box>

        <Flex width="480px">
          <Box width="100%" hidden={!isLargerThan600}>
            <InputGroup onClick={onOpenSearchPopup} cursor="pointer">
              <InputLeftElement pointerEvents="none">
                <IconSearch color="white" />
              </InputLeftElement>
              <Input
                bg="#232f44"
                borderRadius="2xl"
                placeholder="Search token address, pair address or name"
                type="search"
                fontSize="sm"
                color="white"
                width="100%"
                readOnly
                _focus={{ boxShadow: 'none' }}
              />
            </InputGroup>
          </Box>
          <Flex justifyContent="center" width="100%" hidden={isLargerThan600}>
            <IconSearch
              color="white"
              cursor="pointer"
              onClick={onOpenSearchPopup}
            />
          </Flex>
        </Flex>

        <Flex alignItems="center" gap={3}>
          <Box>
            <Flex alignItems="center">
              <Box>
                <Avatar size={'xs'} src={ethereumLogo} />
              </Box>
              <Box>
                <Text
                  as="span"
                  color="#EAEBEF"
                  fontSize="xs"
                  fontWeight="extrabold"
                >
                  ${parseFloat(chain.price).toFixed(2)}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box>
            <Flex alignItems="center">
              <Box>
                <Avatar size={'xs'} src={gasLogo} />
              </Box>
              <Box>
                <Text
                  as="span"
                  color="#EAEBEF"
                  fontSize="xs"
                  fontWeight="extrabold"
                >
                  {Math.ceil(
                    parseFloat(ethers.formatUnits(chain.fee || '0', 'gwei'))
                  )}{' '}
                  {isLargerThan920 && 'GWEI'}
                </Text>
              </Box>
            </Flex>
          </Box>
          <LoginButton variant="solid" colorScheme="teal" size="sm" mr={4} />
        </Flex>
      </Flex>
      <SearchDrawer isOpen={isOpenSearch} onClose={onCloseSearch} size="2xl" />
      <SearchPopup
        isOpen={isOpenSearchPopup}
        onClose={onCloseSearchPopup}
        usdPrice={chain.price}
      />
    </Box>
  );
}

export default observer(Navbar);
