import React, { useState, useEffect, useCallback } from 'react';
import { Avatar, Td, Text, Box, Link, Flex } from '@chakra-ui/react';
import { ethers } from 'ethers';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FiFilter } from 'react-icons/fi';

dayjs.extend(relativeTime);

import etherScanLogo from '../../../../assets/etherscanLogo.svg';
import whale from '../../../../assets/whale.svg';
import dolphin from '../../../../assets/dolphin.svg';
import crab from '../../../../assets/crab.svg';
import fish from '../../../../assets/fish.svg';
import shrimp from '../../../../assets/shrimp.svg';
import stars from '../../../../assets/stars.svg';
import { Transaction } from '../../../../api/PoolApi';
import { shortenAddr } from '../../../../helpers/AddressHelpers';
import { openEtherScanLink } from '../../../../helpers/LinkHelpers';
import { formatUSD, toFixedNumber } from '../../../../helpers/MoneyHelpers';

type Props = {
  transaction: Transaction;
  isMobile: boolean;
  rowColor: string;
  explorer: string;
  onFilterClick: (maker: string) => void;
};

const TransferRow: React.FC<Props> = ({
  transaction,
  isMobile,
  explorer,
  rowColor,
  onFilterClick,
}) => {
  const getDate = (date: string): string => {
    const now = dayjs();
    const transactionDate = dayjs(date);
    const diffInDays = now.diff(transactionDate, 'day');
    const diffInHours = now.diff(transactionDate, 'hour');
    const diffInMinutes = now.diff(transactionDate, 'minute');
    const diffInSeconds = now.diff(transactionDate, 'second');

    if (diffInDays >= 2) {
      return transactionDate.format('MMM DD HH:mm:ss');
    } else if (diffInHours > 0) {
      return `${diffInHours}h`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes}m`;
    } else {
      return `${diffInSeconds}s`;
    }
  };
  const [date, setDate] = useState(getDate(transaction.date));
  const [showInitialDate, setShowInitialDate] = useState(false);

  const getIcon = useCallback((amountUsd: number) => {
    if (amountUsd > 10000) return whale;
    if (amountUsd > 3000) return dolphin;
    if (amountUsd > 1200) return crab;
    if (amountUsd > 500) return fish;
    return shrimp;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(getDate(transaction.date));
      setShowInitialDate(false);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Td borderColor="#273344">
        <Text as="span" fontWeight="semibold" color={rowColor}>
          {showInitialDate ? getDate(transaction.date) : date}
        </Text>
      </Td>
      <Td borderColor="#273344">
        <Text as="span" fontWeight="semibold" color={rowColor}>
          {formatUSD(parseFloat(transaction.priceUsd).toFixed(18))}
        </Text>
      </Td>
      <Td borderColor="#273344">
        <Flex alignItems="center" gap={2}>
          <Box>
            <Avatar
              size={!isMobile ? '2xs' : 'xs'}
              src={getIcon(parseFloat(transaction.amountUsd))}
            />
          </Box>
          <Box>
            <Text as="span" fontWeight="semibold" color={rowColor}>
              {formatUSD(transaction.amountUsd)}
            </Text>
          </Box>
        </Flex>
      </Td>
      {isMobile && (
        <Td borderColor="#273344">
          <Text as="span" fontWeight="semibold" color={rowColor}>
            {toFixedNumber(
              ethers.formatUnits(transaction.amountToken.split('.')[0]),
              4
            ).toLocaleString()}
          </Text>
        </Td>
      )}
      {isMobile && (
        <Td borderColor="#273344">
          <Text as="span" fontWeight="semibold" color={rowColor}>
            {toFixedNumber(
              ethers.formatUnits(transaction.amountWeth.split('.')[0]),
              4
            )}
          </Text>
        </Td>
      )}
      <Td borderColor="#273344">
        <Flex alignItems="center">
          <FiFilter
            cursor="pointer"
            fill="white"
            onClick={() => onFilterClick(transaction.maker)}
          />
          <Link
            href={`${explorer}/address/${transaction.maker}`}
            isExternal
            color={rowColor}
          >
            {shortenAddr(transaction.maker)}
          </Link>
          {transaction.isFirstSwap && <Avatar src={stars} size="2xs" />}
        </Flex>
      </Td>

      {isMobile && (
        <Td borderColor="#273344">
          <Link href={transaction.etherscanLink} target="_blank">
            <Box
              as="button"
              fontWeight="semibold"
              color={rowColor}
              onClick={() =>
                openEtherScanLink(transaction.etherscanLink, 'tx', explorer)
              }
            >
              <Avatar size={'2xs'} src={etherScanLogo} />
            </Box>
          </Link>
        </Td>
      )}
    </>
  );
};

export default TransferRow;
