import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  TableContainer,
  Link,
  Spinner,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';

import { getWalletTransactions } from '../../../api/PoolApi';
import { formatUSD } from '../../../helpers/MoneyHelpers';
import { shortenAddr } from '../../../helpers/AddressHelpers';
import { Store } from '../../../stores';

type WalletTransaction = {
  txHash: string;
  amountOfTokens: string;
  price: string;
  date: string;
  type: 'buy' | 'sell';
};

type Props = {
  poolAddress: string;
  poolPrice: string;
  decimals?: number;
};

const WalletTransactions = observer(
  ({ poolAddress, poolPrice, decimals = 18 }: Props) => {
    const [transactions, setTransactions] = useState<WalletTransaction[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { chain } = Store;

    const fetchWalletTransactions = useCallback(async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await getWalletTransactions(poolAddress);
        setTransactions(data);
      } catch (err) {
        setError('Failed to fetch wallet transactions');
        console.error('Error fetching wallet transactions:', err);
      } finally {
        setIsLoading(false);
      }
    }, [poolAddress]);

    useEffect(() => {
      fetchWalletTransactions();
    }, [fetchWalletTransactions]);

    const getColor = useCallback((type: string): string => {
      return type === 'buy' ? '#16C670' : '#EA3942';
    }, []);

    const formatDate = useCallback((date: string): string => {
      return dayjs(date).format('MMM DD HH:mm');
    }, []);

    const calculatePrice = useCallback(
      (amount: string, price: string): string => {
        const amountBN = Number(amount);
        const priceBN = Number(price);
        const result = (amountBN * priceBN) / 10 ** decimals;
        return result.toString();
      },
      []
    );

    const openEtherScanLink = useCallback(
      (tx: string) => {
        window.open(
          `${chain.explorer}/tx/${tx}`,
          '_blank',
          'noopener,noreferrer'
        );
      },
      [chain.explorer]
    );

    if (isLoading) {
      return (
        <Box textAlign="center" py={10}>
          <Spinner color="white" />
        </Box>
      );
    }

    if (error) {
      return (
        <Box textAlign="center" py={10}>
          <Text color="red.500">{error}</Text>
        </Box>
      );
    }

    if (transactions.length === 0) {
      return (
        <Box textAlign="center" py={10}>
          <Text color="white">You do not have any swaps on this pool.</Text>
        </Box>
      );
    }

    return (
      <TableContainer
        bg="#152036"
        borderRadius="8px"
        boxShadow="0 2px 4px rgba(0, 0, 0, 0.08)"
        w="100%"
        maxH="500px"
        overflowY="auto"
      >
        <Table overflowX="auto" size="sm" variant="simple" colorScheme="dark">
          <Thead position="sticky" top={0} bg="#152036" zIndex={1}>
            <Tr>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                Tokens
              </Th>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                Price
              </Th>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                Current Price
              </Th>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                Date
              </Th>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                Tx
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map((tx) => (
              <Tr key={tx.txHash} _hover={{ bg: '#1c2b49' }}>
                <Td borderColor="#273344">
                  <Text
                    as="span"
                    fontWeight="semibold"
                    color={getColor(tx.type)}
                  >
                    {ethers.formatUnits(tx.amountOfTokens.split('.')[0], 18)}
                  </Text>
                </Td>
                <Td borderColor="#273344">
                  <Text
                    as="span"
                    fontWeight="semibold"
                    color={getColor(tx.type)}
                  >
                    ${formatUSD(calculatePrice(tx.amountOfTokens, tx.price))}
                  </Text>
                </Td>
                <Td borderColor="#273344">
                  <Text
                    as="span"
                    fontWeight="semibold"
                    color={getColor(tx.type)}
                  >
                    ${formatUSD(calculatePrice(tx.amountOfTokens, poolPrice))}
                  </Text>
                </Td>
                <Td borderColor="#273344">
                  <Text
                    as="span"
                    fontWeight="semibold"
                    color={getColor(tx.type)}
                  >
                    {formatDate(tx.date)}
                  </Text>
                </Td>
                <Td borderColor="#273344">
                  <Link
                    href="#"
                    color="#5C8299"
                    fontWeight="semibold"
                    onClick={() => openEtherScanLink(tx.txHash)}
                  >
                    {shortenAddr(tx.txHash)}
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
);

export default WalletTransactions;
