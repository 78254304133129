import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import Layout from '../../layout/TokenLayout';
import { commarize } from '../../helpers/MoneyHelpers';

import styles from './MobilePoolExplorer.module.css'; // We'll create this CSS module
import Chart from './components/chart/Chart';
import { PoolInfo } from '../../api/PoolApi';
import TokenInfo from './components/token-info/TokenInfo';
import TokenName from './components/token-name/tokenName';
import Socials from './components/socials/Socials';
import TransactionList from './components/transfers/TransferTable';
import TokenPrice from './components/token-price/TokenPrice';
import Positions from './components/positions/Positions';
import TokenBalance from './components/token-balance/TokenBalance';

type Tab = 'Info' | 'Chart' | 'Txnes';

type Props = {
  viewedPool: PoolInfo | null;
  usdPrice: string;
  setUsdPrice: (val: string) => void;
};

function MobilePoolExplorer({ viewedPool, usdPrice, setUsdPrice }: Props) {
  const [activeTab, setActiveTab] = useState<Tab>('Info');

  if (!viewedPool) {
    return <>Loadding</>;
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'Info':
        return (
          <>
            <Flex justifyContent="space-between">
              <TokenName
                tokenName={viewedPool.name}
                tokenTicker={viewedPool.ticker}
                otherTicker={viewedPool.otherTicker}
                logo={viewedPool.logo}
                poolAddress={viewedPool.poolAddress}
              />
              <TokenPrice
                usdPrice={usdPrice}
                change={viewedPool.change}
                contractAddress={viewedPool.tokenAddress || ''}
              />
            </Flex>
            <Socials
              tokenName={viewedPool.name}
              tokenAddress={viewedPool.tokenAddress}
              auditToken={viewedPool.auditStatus}
              twitter={viewedPool.socials.twitter}
              telegram={viewedPool.socials.telegram}
              website={viewedPool.socials.website}
              discord={viewedPool.socials.discord}
              audits={viewedPool.audits || []}
              auditResult={viewedPool.auditResult}
            />
            <TokenInfo
              marketCap={String(
                (parseFloat(viewedPool.totalSupply) * parseFloat(usdPrice)) /
                  10 ** 18
              )}
              pooledWeth={viewedPool.liquidity || '0'}
              totalSupply={viewedPool.totalSupply}
              buyTax={`${viewedPool.buyTax || 0}%`}
              sellTax={`${viewedPool.buyTax || 0}%`}
              liqStatus="Burned"
              tokenAddress={viewedPool.tokenAddress}
              volume={viewedPool.volume}
              banner={viewedPool.banner}
            />

            <Positions
              pnl={384.12}
              positionValue={224.49}
              balance={132.42312344}
              token={viewedPool.name}
              avgPrice={8.264729859559749}
              investUsd={132}
              investEth={1.02}
              holderRank={324}
            />
          </>
        );
      case 'Chart':
        return (
          <>
            <Flex
              width="100%"
              alignItems="end"
              justifyContent="space-between"
              as="span"
              color="green.400"
              fontWeight="bold"
              fontSize="sm"
              px="5px"
            >
              <TokenBalance
                balanceWording={`Your ${viewedPool.name} Estimated Balance`}
                contractAddress={viewedPool.tokenAddress || ''}
                usdPrice={usdPrice}
              />
            </Flex>
            <Chart />
            <TransactionList
              poolAddress={viewedPool.poolAddress}
              tokenSymbol={viewedPool.ticker}
              moneySymbol={viewedPool.otherTicker}
              setUsdPrice={setUsdPrice}
            />
          </>
        );
      case 'Txnes':
        return (
          <Box h="70vh" marginTop="10px">
            <Flex
              width="100%"
              alignItems="end"
              justifyContent="space-between"
              as="span"
              color="green.400"
              fontWeight="bold"
              fontSize="sm"
              px="5px"
            >
              <TokenBalance
                balanceWording={`Your ${viewedPool.name} Estimated Balance`}
                contractAddress={viewedPool.tokenAddress || ''}
                usdPrice={usdPrice}
              />
            </Flex>
            <TransactionList
              poolAddress={viewedPool.poolAddress}
              tokenSymbol={viewedPool.ticker}
              moneySymbol={viewedPool.otherTicker}
              setUsdPrice={setUsdPrice}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <title>
        ${viewedPool.ticker} $
        {commarize(
          String(
            (parseFloat(viewedPool.totalSupply) * parseFloat(usdPrice)) /
              10 ** 18
          )
        )}{' '}
        - DexMap
      </title>
      <Layout showTrend={activeTab === 'Info'}>
        <div className={styles.mobilePoolExplorer}>
          <div className={styles.content}>{renderContent()}</div>
          <div className={styles.tabsContainer}>
            {(['Info', 'Chart', 'Txnes'] as Tab[]).map((tab) => (
              <button
                key={tab}
                className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default MobilePoolExplorer;
