import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { Store } from '../../stores';
import { getPools, getSocialsUpdatedTokens, Pool } from '../../api/PoolApi';
import TradedPairs from './components/traded-pairs/TradedPairs';
import SocialUpdates from './components/social-updates/SocialUpdates';
import Trending from './components/trending/Trending';

function MainPage() {
  const [pools, setPools] = useState<Pool[] | null[]>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [socialUpdatedPools, setSocialUpdatedPools] = useState<Pool[]>([]);
  const [trending, setTrending] = useState<Pool[]>([]);
  const { socket } = Store;
  const poolQueueRef = useRef<Pool[]>([]);
  const processingRef = useRef<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [poolsFromAPI, trendingPools, socials] = await Promise.all([
          getPools(),
          getPools('trending'),
          getSocialsUpdatedTokens(),
        ]);

        if (poolsFromAPI) {
          setPools(poolsFromAPI);
        }
        if (trendingPools) {
          setTrending(trendingPools);
        }
        if (socials) {
          setSocialUpdatedPools(socials);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error appropriately (e.g., show error message to user)
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Store.connectToSocket();
    if (socket) {
      socket.onmessage = function (event) {
        const { data: dataStr } = event;
        const data = JSON.parse(dataStr);
        if (data.type == 'tradedPool') {
          const { data: pool } = data;
          const newPool = {
            ...pool,
            poolAddress: pool.poolAddress,
            type: data.swapType,
          };
          poolQueueRef.current.push(newPool);
          if (!processingRef.current) {
            processPoolQueue();
          }
        }
      };
    }
  }, [socket, socket?.readyState]);

  const processPoolQueue = () => {
    if (poolQueueRef.current.length === 0) {
      processingRef.current = false;
      return;
    }

    processingRef.current = true;
    const poolToProcess = poolQueueRef.current.shift();

    pools[0] &&
      setPools((prev) => [
        poolToProcess!,
        ...(prev as Pool[]).filter(
          (a: Pool) => a.poolAddress !== poolToProcess!.poolAddress
        ),
      ]);

    setTimeout(processPoolQueue, 1000);
  };

  return (
    <>
      <title> DexMap - Main Page </title>
      <Box marginTop="20px" marginRight="10px" marginLeft="10px">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 4, md: 3 }}
          margin="0 12px"
        >
          <Box flex={{ base: '1', md: '3' }}>
            <Trending pools={trending} />
          </Box>
          <Box flex={{ base: '1', md: '2' }}>
            <SocialUpdates tokens={socialUpdatedPools} />
          </Box>
        </Stack>
        <Box margin="10px 0">
          <TradedPairs tokens={pools} />
        </Box>
      </Box>
    </>
  );
}

export default MainPage;
