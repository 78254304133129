import React, { useEffect } from 'react';
import { Box, Text, Grid, GridItem, Stack, Flex } from '@chakra-ui/react';
import { IconStarFilled } from '@tabler/icons-react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Card from '../../../../components/Card';
import { Store } from '../../../../stores';
import { formatUSD } from '../../../../helpers/MoneyHelpers';

const FavoriteList = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      Store.syncFavorites();
    }, 6000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Card
      title={
        <Text fontSize="lg" fontWeight="extrabold" color="#FFFFFF">
          Favorites
        </Text>
      }
      uncollapsableChildren={undefined}
      collapsableChildren={
        <Stack spacing="2">
          {Store.favorites.map((pool) => (
            <Grid
              onClick={() => navigate(`/eth/pool/${pool.poolAddress}`)}
              cursor={'pointer'}
              key={`fav-${pool.poolAddress}`}
              alignItems={'center'}
              templateColumns="2fr 1fr 1fr"
              gap={4}
            >
              <GridItem>
                <Flex gap={2} align="center">
                  <IconStarFilled
                    width="15"
                    style={{ color: 'orange', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      Store.removeFavorite(pool.poolAddress);
                    }}
                  />
                  <Text fontSize="sm" fontWeight="bold" color="#FFFF">
                    {pool.tokenSymbol}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Text
                  fontSize="xs"
                  fontWeight="semiBold"
                  color={
                    parseFloat(pool.changeIn24H) < 0 ? '#F08080' : '#16C671'
                  }
                >
                  {Math.abs(parseFloat(pool.changeIn24H)).toFixed(2)}%
                </Text>
              </GridItem>
              <GridItem>
                <Text
                  fontSize="xs"
                  fontWeight="semiBold"
                  color={
                    parseFloat(pool.changeIn24H) < 0 ? '#F08080' : '#16C671'
                  }
                >
                  {pool.poolPrice && formatUSD(pool.poolPrice)}
                </Text>
              </GridItem>
            </Grid>
          ))}
          {Store.favorites.length === 0 && (
            <Box>
              <Text color="white" fontSize="small" fontWeight="500">
                You do not have any favorite tokens
              </Text>
            </Box>
          )}
        </Stack>
      }
    />
  );
};

export default observer(FavoriteList);
