import React, { ReactNode, useEffect, useState } from 'react';
import {
  chakra,
  VisuallyHidden,
  Box,
  Flex,
  useDisclosure,
  Button,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaTwitter, FaTelegram, FaGlobe, FaDiscord } from 'react-icons/fa';
import metamaskLogo from '../../../../assets/metamask.svg';
import etherscanLogo from '../../../../assets/etherscanLogo.svg';
import shieldTrue from '../../../../assets/shieldTrue.svg';
import shieldWarning from '../../../../assets/shiledWarning.svg';
import shieldError from '../../../../assets/shieldError.svg';
import discount from '../../../../assets/discount.svg';
import Audit from '../audit/Audit';
import { Audit as AuditItem, AuditResult } from '../../../../api/PoolApi';

export const SocialButton = ({
  children,
  label,
  href,
  color,
  disabled,
}: {
  children: ReactNode;
  label: string;
  href: string;
  color?: string;
  disabled?: boolean;
}) => {
  const actualColor = color ? color : disabled ? 'gray' : 'white';

  return (
    <chakra.button
      rounded={'full'}
      w={8}
      h={8}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      as={'a'}
      target="_blank"
      href={disabled ? undefined : href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      rel="nofollow"
      color={actualColor}
      disabled={disabled}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

type Props = {
  tokenName: string;
  tokenAddress: string;
  auditToken: string;
  telegram?: string;
  website?: string;
  twitter?: string;
  discord?: string;
  audits: AuditItem[];
  auditResult: AuditResult;
};

const Socials = ({
  tokenName,
  tokenAddress,
  telegram,
  website,
  twitter,
  discord,
  audits,
  auditResult,
}: Props) => {
  const size = 'md';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [auditLevel, setAuditLevel] = useState('notChecked');
  const [auditLevelNumber, setAuditLevelNumber] = useState(0);
  const [isLargerThan578] = useMediaQuery('(min-width: 578px)');
  const [isLargerThan479] = useMediaQuery('(min-width: 479px)');

  const handleClick = () => {
    onOpen();
  };

  useEffect(() => {
    if (!auditResult.isAudited) {
      return;
    }
    console.log('audit repooort', auditResult);
    if (auditResult.highRisk > 0) {
      setAuditLevel('honeypot');
      setAuditLevelNumber(auditResult.highRisk);
      return;
    } else if (auditResult.mediumRisk > 0) {
      setAuditLevel('has_issues');
      setAuditLevelNumber(auditResult.mediumRisk);
      return;
    }
    setAuditLevel('safe');
  }, [auditResult]);

  return (
    <>
      <Box
        bg="#152036"
        borderRadius="8px"
        boxShadow="0 2px 4px rgba(0, 0, 0, 0.08)"
        padding="5px"
        overflowX="auto"
        color="white"
        maxH="45px"
      >
        <Flex justifyContent="center">
          <SocialButton
            label="Website"
            href={`https://etherscan.io/token/${tokenAddress}`}
          >
            <img src={etherscanLogo} width={'18px'} alt="etherscan Logo" />
          </SocialButton>
          <SocialButton
            label="Telegram"
            href={`https://t.me/${telegram}`}
            disabled={!telegram}
          >
            <FaTelegram />
          </SocialButton>
          <SocialButton
            label="Website"
            href={website || ''}
            disabled={!website}
          >
            <FaGlobe />
          </SocialButton>
          <SocialButton
            label="x"
            href={`https://x.com/${twitter}`}
            disabled={!twitter}
          >
            <FaTwitter />
          </SocialButton>

          <SocialButton
            label="Discord"
            href={`https://discord.gg/${discord}`}
            disabled={!discord}
          >
            <FaDiscord />
          </SocialButton>
          <SocialButton label="Metamask" href="metamesk">
            <img src={metamaskLogo} width={'23px'} alt="metamask Logo" />
          </SocialButton>
          {!website && (
            <Button
              as="a"
              href="https://forms.gle/TYzgZ3hwSddm9He88"
              target="_blank"
              onClick={handleClick}
              size={'sm'}
              fontWeight={'semibold'}
              fontSize={'xs'}
              mr={1}
              style={{
                background: ' #319795',
                color: 'white',
                position: 'relative',
              }}
            >
              <Text as="span" fontWeight={'bold'} paddingRight="10px">
                Update!{' '.repeat(4)}
              </Text>
              <Box
                position="absolute"
                top="-5px"
                right="-6px"
                width="30px"
                height="30px"
                overflow="visible"
              >
                <img
                  src={discount}
                  width={'100%'}
                  alt="discount"
                  style={{ position: 'absolute', top: 0, right: 0 }}
                />
              </Box>
            </Button>
          )}

          {!!audits.length && (
            <>
              {auditLevel == 'honeypot' ? (
                <>
                  <Box hidden={!isLargerThan578 && isLargerThan479}>
                    <Button
                      onClick={handleClick}
                      //   border={'red 1px solid'}
                      size={'sm'}
                      fontWeight={'semibold'}
                      fontSize={'xs'}
                      // audit no risk : rgb(39 128 85)
                      // audit some risk: rgb(229 148 0)
                      // audit Danger :rgb(187 17 14)
                      style={{ background: 'rgb(187 17 14)', color: 'white' }}
                    >
                      <img src={shieldError} width={'25px'} alt="audit Logo" />
                      <Text as="span" fontWeight={'extrabold'}>
                        Audit ({auditLevelNumber})
                      </Text>
                    </Button>
                  </Box>
                </>
              ) : auditLevel == 'safe' ? (
                <>
                  <Box hidden={!isLargerThan578 && isLargerThan479}>
                    <Button
                      onClick={handleClick}
                      //   border={'red 1px solid'}
                      size={'sm'}
                      fontWeight={'semibold'}
                      fontSize={'xs'}
                      // audit no risk : rgb(39 128 85)
                      // audit some risk: rgb(229 148 0)
                      // audit Danger :rgb(187 17 14)
                      style={{ background: ' rgb(39 128 85)', color: 'white' }}
                    >
                      <img src={shieldTrue} width={'25px'} alt="audit Logo" />
                      <Text as="span" fontWeight={'extrabold'}>
                        Audit
                      </Text>
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box hidden={!isLargerThan578 && isLargerThan479}>
                    <Button
                      onClick={handleClick}
                      //   border={'red 1px solid'}
                      size={'sm'}
                      fontWeight={'semibold'}
                      fontSize={'xs'}
                      // audit no risk : rgb(39 128 85)
                      // audit some risk: rgb(229 148 0)
                      // audit Danger :#EF5350
                      style={{ background: 'rgb(229 148 0)', color: 'white' }}
                    >
                      <img
                        src={shieldWarning}
                        width={'25px'}
                        alt="audit Logo"
                      />
                      <Text as="span" fontWeight={'extrabold'}>
                        Audit ({auditLevelNumber})
                      </Text>
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}

          {!!audits.length && (
            <>
              {auditLevel == 'honeypot' ? (
                <Flex
                  alignItems="center"
                  hidden={isLargerThan578 || !isLargerThan479}
                  onClick={handleClick}
                >
                  <img src={shieldError} width={'25px'} alt="audit Logo" />
                </Flex>
              ) : auditLevel == 'safe' ? (
                <Flex
                  alignItems="center"
                  hidden={isLargerThan578 || !isLargerThan479}
                  onClick={handleClick}
                >
                  <img src={shieldTrue} width={'25px'} alt="audit Logo" />
                </Flex>
              ) : (
                <Flex
                  alignItems="center"
                  hidden={isLargerThan578 || !isLargerThan479}
                  onClick={handleClick}
                >
                  <img src={shieldWarning} width={'25px'} alt="audit Logo" />
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Box>
      {audits.length > 0 && (
        <Audit
          isOpen={isOpen}
          onClose={onClose}
          size={size}
          tokenName={tokenName}
          audit={audits[0]}
        />
      )}
    </>
  );
};
export default Socials;
