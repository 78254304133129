import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Box,
  Text,
  useBreakpointValue,
  VStack,
  Avatar,
  Grid,
  Flex,
  Divider,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { IconSearch } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { searchPools, SearchResult } from '../api/PoolApi';
import SearchIconSvg from '../assets/search.svg';
import { formatUSD } from '../helpers/MoneyHelpers';

type SearchPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  usdPrice: string;
};

function commarize(strNum: string) {
  const actualNum = parseInt(strNum);
  if (actualNum >= 1e3) {
    const units = ['k', 'M', 'B', 'T'];
    const order = Math.floor(Math.log(actualNum) / Math.log(1000));
    const unitname = units[order - 1];
    const num = Math.floor(actualNum / 1000 ** order);
    return num + unitname;
  }

  return parseFloat(strNum).toFixed(2);
}

const SearchPopup: React.FC<SearchPopupProps> = ({
  isOpen,
  onClose,
  usdPrice,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const modalSize = useBreakpointValue({ base: 'full', md: '5xl' });
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchQuery.length > 2) {
        const results = await searchPools(searchQuery);
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [searchQuery]);

  useEffect(() => {
    if (isOpen) {
      setSearchQuery('');
      setSearchResults([]);
    }
  }, [isOpen]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent
        bg="#152036"
        color="white"
        maxHeight="80vh"
        overflowY="auto"
      >
        <ModalHeader borderBottom="1px solid #2D3748" pb={4}>
          <Flex gap={2} alignItems="center">
            <Box>
              <Avatar size={'sm'} src={SearchIconSvg} />
            </Box>
            <Box>
              <Text fontWeight="extrabold" fontSize="xl">
                Search on DEXMAP
              </Text>
            </Box>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4} px={4}>
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents="none">
              <IconSearch color="gray.300" />
            </InputLeftElement>
            <Input
              ref={inputRef}
              placeholder="Search for tokens, pools, or addresses"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              size="lg"
              bg="#232f44"
              border="none"
              borderRadius="xl"
              _focus={{
                boxShadow: 'none',
                border: '1px solid',
                borderColor: '#4299E1',
              }}
              _hover={{ bg: '#2A3749' }}
            />
          </InputGroup>
          <VStack align="stretch" spacing={0}>
            {searchResults.map((result, index) => (
              <Box key={result.poolAddress}>
                <Box
                  onClick={() => {
                    navigate(`/eth/pool/${result.poolAddress}`);
                    onClose();
                  }}
                  cursor="pointer"
                  py={3}
                  px={2}
                  _hover={{ bg: '#2A3943' }}
                >
                  <Grid
                    templateColumns={{ base: '1fr', md: '1fr 3fr' }}
                    gap={4}
                  >
                    <Flex align="center">
                      <Text
                        fontWeight="bold"
                        fontSize="sm"
                        mr={2}
                        minWidth="20px"
                      >
                        #{index + 1}
                      </Text>
                      <Avatar
                        size="sm"
                        src={result.tokenLogo}
                        mr={2}
                        name={result.tokenName}
                      />
                      <Box>
                        <Text fontWeight="bold" fontSize="sm">
                          {result.tokenName}
                        </Text>
                        <Text fontSize="xs" color="#93a3b8">
                          {result.tokenSymbol}
                        </Text>
                      </Box>
                    </Flex>
                    <Grid
                      templateColumns="repeat(4, 1fr)"
                      gap={4}
                      alignItems="center"
                    >
                      <Text fontSize="sm" fontWeight="bold">
                        {formatUSD(result.poolPrice)}
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color={
                          parseFloat(result.changeIn24H) >= 0
                            ? '#16C670'
                            : '#EA3942'
                        }
                      >
                        {parseFloat(result.changeIn24H) > 0 ? '+' : ''}
                        {parseFloat(result.changeIn24H).toFixed(2)}%
                      </Text>
                      <Text fontSize="sm" color="#93a3b8">
                        Vol: ${commarize(result.volume)}
                      </Text>
                      <Text fontSize="sm" color="#93a3b8">
                        Liq: $
                        {parseFloat(result.liquidity) > 0
                          ? commarize(
                              String(
                                (parseFloat(result.liquidity) / 10 ** 18) *
                                  parseFloat(usdPrice)
                              )
                            )
                          : '0'}
                      </Text>
                    </Grid>
                  </Grid>
                </Box>
                {index < searchResults.length - 1 && (
                  <Divider borderColor="gray.600" />
                )}
              </Box>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchPopup;
