import { SiweMessage } from 'siwe';
import Cookies from 'js-cookie';

import { axiosAPI } from './BaseApi';

export function createSiweMessage(address: string, statement: string) {
  const siweMessage = new SiweMessage({
    domain: window.location.host,
    address,
    statement,
    uri: origin,
    version: '1',
    chainId: 1,
  });
  return siweMessage.prepareMessage();
}

export async function signInWithEIP(message: any): Promise<boolean> {
  try {
    const { data } = await axiosAPI.post(`/api/users/sign-in`, message);
    // TODO: SET same site
    Cookies.set('userVal', data.key, { expires: 1 });
    return true;
  } catch {
    return false;
  }
}
