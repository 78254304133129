import React, { useState, useRef } from 'react';
import {
  Box,
  Link as ChakraLink,
  Text,
  Stack,
  Flex,
  Divider,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { IconCopy } from '@tabler/icons-react';
import { Store } from '../../../../stores';
import uniLogo from '../../../../assets/uniLogo.svg';
import wethLogo from '../../../../assets/wethLogo.svg';
import Card from '../../../../components/Card';

import { shortenAddr } from '../../../../helpers/AddressHelpers';
import { commarize } from '../../../../helpers/MoneyHelpers';

type Props = {
  marketCap: string;
  pooledWeth: string;
  totalSupply: string;
  buyTax: string;
  sellTax: string;
  liqStatus: string;
  tokenAddress: string;
  volume: string;
  banner?: string;
};

const AddressInfo = ({
  label,
  address,
}: {
  label: string;
  address: string;
}) => {
  const [showCopied, setShowCopied] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { chain } = Store;

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    setShowCopied(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  return (
    <Flex gap={1} alignItems="center">
      <Box>
        <Text as="span" fontSize="2xs" color={'white'} fontWeight={'bold'}>
          {label}:{' '}
          <ChakraLink
            href={`${chain.explorer}/token/${address}`}
            isExternal
            _hover={{ color: '#198CD8' }}
            rel="nofollow"
          >
            {shortenAddr(address)}
          </ChakraLink>
        </Text>
      </Box>
      <Box>
        <Tooltip
          label={showCopied ? 'Copied' : 'Copy Address'}
          bg="black"
          color="white"
          placement="top"
          border={'ActiveBorder'}
          hasArrow
          isOpen={showCopied}
        >
          <Tooltip
            label="Copy Address"
            bg="black"
            color="white"
            border={'ActiveBorder'}
            placement="top"
            hasArrow
          >
            <Box>
              <IconCopy
                size="15px"
                style={{ color: 'white', cursor: 'pointer' }}
                onClick={handleCopy}
              />
            </Box>
          </Tooltip>
        </Tooltip>
      </Box>
    </Flex>
  );
};

const TokenInfo = ({
  marketCap,
  pooledWeth,
  totalSupply,
  buyTax,
  sellTax,
  tokenAddress,
  volume,
  banner,
}: Props) => {
  const adjustedVolume = commarize(volume);
  return (
    <Box>
      {banner && (
        <Image
          src={banner}
          alt="banner"
          width="99%"
          paddingLeft={'1%'}
          height="auto"
          maxHeight="500px"
          objectFit="cover"
          borderRadius="md"
          marginBottom={'-10%'}
          borderTopRadius={'5%'}
        />
      )}

      <>
        <Card
          title={
            <>
              <Text
                as="span"
                fontSize="lg"
                fontWeight="extrabold"
                color="#FFFF"
              >
                Information
              </Text>
              <img
                src={uniLogo}
                width={'23px'}
                style={{
                  background: 'white',
                  borderRadius: '50%',
                  height: '23px',
                  marginBottom: '3px',
                }}
                alt="uniswap logo"
              />
              <img
                src={wethLogo}
                width={'23px'}
                style={{
                  marginLeft: '-15px',
                  background: 'white',
                  borderRadius: '50%',
                  height: '23px',
                  marginBottom: '3px',
                }}
                alt="ether(weth) logo"
              />
            </>
          }
          uncollapsableChildren={
            <>
              <Flex
                flexDirection={{ base: 'column', xl: 'row' }}
                justifyContent={{ base: 'start', xl: 'space-between' }}
                alignItems={{ base: 'start' }}
              >
                <AddressInfo address={tokenAddress} label="Contract Address" />
              </Flex>
              <Divider borderColor="#273344" />
              <Flex justifyContent="space-between" alignItems="center">
                <Text as="span" fontSize="xs" fontWeight="bold" color="#FFFF">
                  Market Cap
                </Text>
                <Text as="span" fontSize="sm" fontWeight="900" color="#EAEBEF">
                  ${commarize(marketCap)}
                </Text>
              </Flex>
              <Divider borderColor="#273344" />
              <Flex justifyContent="space-between" alignItems="center">
                <Text as="span" fontSize="xs" fontWeight="bold" color="#FFFF">
                  Volume
                </Text>
                <Text as="span" fontSize="sm" fontWeight="800" color="#EAEBEF">
                  $
                  {parseFloat(adjustedVolume) === parseFloat('0.0')
                    ? '0.0'
                    : adjustedVolume}
                </Text>
              </Flex>
              <Divider borderColor="#273344" />
              <Flex justifyContent="space-between" alignItems="center">
                <Text as="span" fontSize="sm" fontWeight="bold" color="#FFFF">
                  Liquidity
                </Text>
                <Text as="span" fontSize="sm" color="#EAEBEF" fontWeight="800">
                  {commarize(pooledWeth)}
                </Text>
              </Flex>
            </>
          }
          collapsableChildren={
            <Stack spacing="1">
              <Flex justifyContent="space-between" alignItems="center">
                <Text as="span" fontSize="xs" fontWeight="bold" color="#FFFF">
                  Total Supply
                </Text>
                <Text as="span" fontSize="sm" color="#EAEBEF" fontWeight="800">
                  {commarize(
                    totalSupply
                      .split('.')[0]
                      .slice(0, totalSupply.split('.')[0].length - 18)
                  )}
                </Text>
              </Flex>
              <Divider borderColor="#273344" />
              <Flex justifyContent="space-between" alignItems="center">
                <Text as="span" fontSize="xs" fontWeight="bold" color="#FFFF">
                  Buy Tax
                </Text>
                <Text as="span" fontSize="sm" color="#EAEBEF" fontWeight="800">
                  {parseFloat(buyTax) >= 0 ? buyTax : 'Not Checked'}
                </Text>
              </Flex>
              <Divider borderColor="#273344" />
              <Flex justifyContent="space-between" alignItems="center">
                <Text as="span" fontSize="xs" fontWeight="bold" color="#FFFF">
                  Sell Tax
                </Text>
                <Text as="span" fontSize="sm" color="#EAEBEF" fontWeight="800">
                  {parseFloat(sellTax) >= 0 ? sellTax : 'Not Checked'}
                </Text>
              </Flex>

              <Divider borderColor="#273344" />
            </Stack>
          }
        />
      </>
    </Box>
  );
};

export default TokenInfo;
