import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import Layout from '../../layout/TokenLayout';

function LegalAdvice() {
  return (
    <Layout showTrend>
      <Box marginTop="20px" />
      <Text color="white" margin="20px" fontWeight={'bold'}>
        All content on our website, as well as any content accessible through
        hyperlinks, applications, forums, blogs, social media accounts, or other
        platforms associated with DexMap, is provided solely for general
        informational purposes. None of this content is intended to promote or
        market specific products. We do not guarantee the accuracy,
        completeness, or timeliness of the published data. The information
        shared by DexMap should not be interpreted as financial, legal, or any
        other type of advice related to investment opportunities in the markets
        or products mentioned. Any use or reliance on the provided information
        is entirely at the user’s own risk. Users considering investments must
        conduct their own research and analysis, reviewing and verifying all
        data and content before making any decisions. Commercial transactions
        mentioned in the information are high-risk activities that can result in
        significant losses for investors; therefore, it is crucial to seek
        appropriate advice before making any investment decisions. Nothing on
        our website should be construed as an invitation or offer to engage in
        investment activities.
      </Text>
      <Box marginBottom="190px" />
    </Layout>
  );
}
export default observer(LegalAdvice);
