import React, { useState, useEffect } from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { IconStar, IconStarFilled } from '@tabler/icons-react';

import { Store } from '../../../../stores';

type Props = {
  tokenName: string;
  tokenTicker: string;
  otherTicker: string;
  logo: string;
  poolAddress: string;
};

function TokenName({
  tokenName,
  tokenTicker,
  otherTicker,
  logo,
  poolAddress,
}: Props) {
  const [isFaved, setIsFaved] = useState(false);

  const isTokenFaved = () => {
    const faved = Store.isFavorited(poolAddress);
    setIsFaved(faved);
    return faved;
  };

  const favClick = () => {
    if (isTokenFaved()) {
      Store.removeFavorite(poolAddress);
    } else {
      // @ts-expect-error 123123
      Store.addFavorite({
        tokenName: tokenName,
        poolAddress: poolAddress,
      });
      Store.syncFavorites();
    }
    isTokenFaved();
  };

  useEffect(() => {
    isTokenFaved();
  }, []);

  return (
    <Box padding="10px" color="white">
      <Flex gap="3" alignItems="center">
        <Box>
          <Avatar size="md" name={tokenName} src={logo} />
        </Box>
        <Box>
          <Flex flexDirection="column">
            <Text as="span" color="white" fontWeight="extrabold">
              {tokenName}
            </Text>
            <Text as="span" color="gray" fontWeight="semibold" fontSize="xs">
              {tokenTicker} / {otherTicker}
            </Text>
          </Flex>
        </Box>
        <Box onClick={favClick} cursor="pointer">
          {isFaved ? (
            <IconStarFilled size="20px" style={{ color: 'orange' }} />
          ) : (
            <IconStar size="20px" />
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default TokenName;
