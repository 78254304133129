import React from 'react';
import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  CloseButton,
  useBreakpointValue,
  Divider,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { Pool } from '../api/PoolApi';
import trendLogo from '../assets/TrendLogo.svg';
import { commarize } from '../helpers/MoneyHelpers';
import { Store } from '../stores';

type TrendProps = {
  trending: Pool[];
  isOpen: boolean;
  onClose: () => void;
};

const Trend: React.FC<TrendProps> = ({ trending, isOpen, onClose }) => {
  const modalSize = useBreakpointValue({ base: 'full', md: '6xl' });
  const modalMaxHeight = useBreakpointValue({ base: '100vh', md: '80vh' });
  const navigate = useNavigate();
  const { chain } = Store;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent
        bg="#152036"
        color="white"
        maxHeight={modalMaxHeight}
        overflowY="auto"
      >
        <ModalHeader position="sticky" top={0} bg="#152036" zIndex={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap="2">
              <Box>
                <Avatar size="sm" src={trendLogo} />
              </Box>
              <Box>
                <Text fontWeight="extrabold">Trending on DEXMAP</Text>
              </Box>
            </Box>
            <CloseButton size={'md'} onClick={onClose} />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {trending.map((token, index) => (
            <React.Fragment key={token.poolAddress}>
              <Box
                onClick={() => {
                  navigate(`/eth/pool/${token.poolAddress}`);
                  onClose();
                }}
                cursor={'pointer'}
                py={3}
                px={2}
                _hover={{
                  background: '#2A3943',
                }}
              >
                <Flex
                  align="center"
                  justify="space-between"
                  overflowX="auto"
                  width="100%"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                    '-ms-overflow-style': 'none',
                  }}
                >
                  <Flex alignItems="center" flexShrink={0} mr={4}>
                    <Text fontWeight="extrabold" fontSize="sm" width="30px">
                      #{index + 1}
                    </Text>
                    <Avatar
                      size="sm"
                      name={token.tokenName}
                      src={token.tokenLogo}
                      marginLeft="2"
                    />
                    <Text
                      fontWeight="extrabold"
                      fontSize="sm"
                      textAlign="left"
                      ml={2}
                      width="100px"
                      isTruncated
                    >
                      {token.tokenName}
                    </Text>
                  </Flex>
                  {[
                    {
                      label: '24H',
                      value: `${token.changeIn24H.split('.')[0]}%`,
                      color:
                        parseFloat(token.changeIn24H) >= 0
                          ? '#16C670'
                          : '#EA3942',
                    },
                    {
                      label: 'MCAP',
                      value: commarize(
                        (token.marketCap || '0.0').split('.')[0]
                      ),
                    },
                    {
                      label: 'LIQ',
                      value: commarize(
                        String(
                          (parseFloat(token.liquidity) / 10 ** 18) *
                            parseFloat(chain.price)
                        )
                      ),
                    },
                    { label: 'VOL', value: commarize(token.volume) },
                  ].map((item, idx) => (
                    <Flex
                      key={idx}
                      direction="column"
                      align="center"
                      minWidth="80px"
                      mx={2}
                      flexShrink={0}
                    >
                      <Text fontSize="xs" color="#93a3b8">
                        {item.label}
                      </Text>
                      <Text
                        fontWeight="bold"
                        fontSize="sm"
                        color={item.color || 'white'}
                      >
                        {item.value}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </Box>
              <Divider borderColor="gray.600" />
            </React.Fragment>
          ))}
        </ModalBody>
        <ModalFooter justifyContent="center" />
      </ModalContent>
    </Modal>
  );
};

export default Trend;
