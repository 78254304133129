import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Provider } from 'mobx-react';

import PoolExplorerPage from './pages/pool-explorer';
import MainPage from './pages/main-page';
import TradingView from './pages/tech/Tradingview';
import LegalAdvice from './pages/tech/LegalAdvice';
import PrivacyPolicy from './pages/tech/PrivacyPolicy';
import Partnership from './pages/other-pages/Partnership'

import { Store } from './stores';
import { FAV_LIST_KEY } from './stores/types/FavoriteToken';
import { Pool } from './api/PoolApi';
import TagManager from 'react-gtm-module';

function LoadData() {
  useEffect(() => {
    const favListFromLS = JSON.parse(
      localStorage.getItem(FAV_LIST_KEY) || '[]'
    ) as Pool[];
    Store.setFavorites(favListFromLS);
  }, []);
  return <div />;
}

const tagManagerArgs = {
  gtmId: 'GTM-5FNNWGRW',
};
TagManager.initialize(tagManagerArgs);
function App() {
  ReactGA.initialize(String(process.env.REACT_APP_TRACKING_ID));
  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainPage />,
    },
    {
      path: '/eth/pool/:poolAddress',
      element: <PoolExplorerPage />,
    },
    {
      path: '/tech/tradingview',
      element: <TradingView />,
    },
    {
      path: '/legaladvice',
      element: <LegalAdvice />,
    },
    {
      path: '/privacypolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/partnership',
      element: <Partnership />,
    },
  ]);

  return (
    <Provider store={Store}>
      <LoadData />
      <ChakraProvider>
        <RouterProvider router={router} />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
