import React from 'react';
import { Stat, StatHelpText, Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import { formatUSD } from '../../../../helpers/MoneyHelpers';
import TokenBalance from '../token-balance/TokenBalance';

type Props = {
  contractAddress: string;
  usdPrice: string;
  change: string;
};

function TokenPrice({ usdPrice, change, contractAddress }: Props) {
  const floatChange = parseFloat(change);
  const arrow = floatChange < 0 ? '↘' : '↗';
  const arrowColor = floatChange < 0 ? '#EA3943' : '#16C671';

  return (
    <Box padding="10px" color="white">
      <Flex alignItems={{ base: 'start', sm: 'end' }} flexDirection="column">
        <Flex
          alignItems="end"
          flexFlow={{ base: 'row-reverse', md: 'row' }}
          gap={2}
        >
          <Box>
            <Stat>
              <StatHelpText fontWeight="bold" fontSize="xs" color={arrowColor}>
                {arrow}
                {Math.abs(floatChange).toFixed(2)}%
              </StatHelpText>
            </Stat>
          </Box>
          <Box>
            <Text as="span" color="white" fontWeight="extrabold" fontSize="2xl">
              <Tooltip label={parseFloat(usdPrice)} placement="top-start">
                {formatUSD(usdPrice)}
              </Tooltip>
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="end" flexFlow={{ base: 'row', md: 'row' }} gap={2}>
          <Box>
            <Text as="span" color="green.400" fontWeight="bold" fontSize="sm">
              <TokenBalance
                contractAddress={contractAddress}
                usdPrice={usdPrice}
              />
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default TokenPrice;
