import { axiosAPI } from './BaseApi';

export type Chain = {
  fee: string;
  symbol: string;
  explorer: string;
  price: string;
};

export const getChain = async (chainSymbol: string): Promise<Chain> => {
  try {
    const res = await axiosAPI.get(`/api/chain/${chainSymbol}`);
    return res.data as Chain;
  } catch {
    return { fee: '0', symbol: 'eth', explorer: '', price: '0' };
  }
};
