import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Text,
  Textarea,
  Stack,
  Flex,
} from '@chakra-ui/react';

import {
  createCommentForPool,
  Comment,
  getCommentsForPool,
} from '../../../../api/PoolApi';
import LoginButton from '../../../../components/LoginButton';
import { Store } from '../../../../stores';

type Props = {
  poolAddress: string;
};

const colorMap: Record<string, string> = {
  '0': '##A9CCC8',
  '1': '##A9CCC8',
  '2': '##A9CCC8', // 0-2-5-A-b-e-G grubu için
  A: '##A9CCC8',
  b: '##A9CCC8',
  e: '##A9CCC8',
  G: '##A9CCC8',
  '3': '#E7B7A1',
  '4': '#E7B7A1',
  '5': '#E7B7A1', // 3-4-5-B-f-H grubu için
  B: '#E7B7A1',
  f: '#E7B7A1',
  H: '#E7B7A1',
  '6': '#89CD8F',
  '7': '#89CD8F',
  '8': '#89CD8F', // 6-7-8-C-g-A grubu için
  C: '#89CD8F',
  g: '#89CD8F',
  a: '#F3C842',
  d: '#F3C842',
  F: '#F3C842', // a-d-F-H grubu için
  D: '#F3C842',
  h: '#F3C842',
  E: '#F3C842',
};

function Comments({ poolAddress }: Props) {
  const { address } = useWeb3ModalAccount();
  const [currentMessage, setCurrentMessage] = useState('');
  // const [replyTo, setReplyTo] = useState<number | null>(null);
  const [activeCommentId, setActiveCommentId] = useState<number | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const { socket } = Store;

  function getAddressColor(address: string): string {
    const lastChar = address[address.length - 1];
    return colorMap[lastChar] || '#FFFFFF';
  }

  const shortenAddr = (strAddress: string): string => {
    return `${strAddress.slice(0, 4)}...${strAddress.slice(strAddress.length - 4, strAddress.length)}`;
  };

  const sendMessage = async () => {
    if (!address || currentMessage?.length === 0) {
      return;
    }
    const comment = {
      commenterAddress: address,
      text: currentMessage,
    };
    await createCommentForPool(poolAddress, comment);
    setCurrentMessage('');
    // setReplyTo(null);
  };

  const loadComments = async () => {
    if (!poolAddress) {
      return;
    }
    const commentsFromDB = await getCommentsForPool(poolAddress);
    setComments(commentsFromDB);
  };

  const handleMouseOver = (id: number) => {
    document.getElementById(`comment-${id}`)?.classList.add('highlight');
  };

  const handleMouseLeave = (id: number) => {
    if (activeCommentId !== id) {
      document.getElementById(`comment-${id}`)?.classList.remove('highlight');
    }
  };

  const handleReplyClick = (id: number) => {
    // setReplyTo(id);
    setCurrentMessage(`#${id} `);
  };

  const handleReferenceHover = (refId: number) => {
    if (refId !== activeCommentId) {
      document.getElementById(`comment-${refId}`)?.classList.add('highlight');
    }
  };

  const handleReferenceLeave = (refId: number) => {
    if (refId !== activeCommentId) {
      document
        .getElementById(`comment-${refId}`)
        ?.classList.remove('highlight');
    }
  };

  const handleClickOutside = () => {
    if (activeCommentId !== null) {
      document
        .getElementById(`comment-${activeCommentId}`)
        ?.classList.remove('highlight');
      setActiveCommentId(null);
    }
  };

  const renderCommentText = (text: string) => {
    const parts = text.split(/(#\d+)/); // Hashtag ile ayrılan kısımları buluyoruz
    return parts.map((part, index) => {
      if (part.startsWith('#')) {
        const refId = parseInt(part.substring(1), 10); // # işaretini çıkarıp ID'yi alıyoruz
        return (
          <Text
            as="span"
            key={index}
            color="green.400"
            textDecoration="underline"
            cursor="pointer"
            fontWeight="800"
            onMouseOver={() => handleReferenceHover(refId)}
            onMouseLeave={() => handleReferenceLeave(refId)}
          >
            {part}
          </Text>
        );
      } else {
        return (
          <Text as="span" key={index} color="#FFFFFF">
            {part}
          </Text>
        );
      }
    });
  };

  useEffect(() => {
    loadComments();
  }, []);

  useEffect(() => {
    Store.connectToSocket();
    if (socket) {
      socket.onmessage = function (event) {
        const { data: dataStr } = event;
        const data = JSON.parse(dataStr);
        if (data.type == 'comment') {
          const { data: comment } = data;
          if (comment.poolAddress !== poolAddress) {
            return;
          }
          if (comments.map((c) => c.id).includes(comment.id)) {
            return;
          }
          setComments((prev) => [comment, ...prev]);
        }
      };
    }
  }, [socket, socket?.readyState]);

  return (
    <div onClick={handleClickOutside}>
      <Box w="inherit" marginBottom="5px">
        <Textarea
          rows={1}
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          color="white"
          placeholder="Write your message"
        />
        <Flex>
          {Store.userState === 'logged_in' ? (
            <Button
              onClick={sendMessage}
              marginTop="8px"
              bg="#319795"
              color="white"
              width="100%"
              _hover={{ bg: '#2C7B7B' }}
            >
              Send
            </Button>
          ) : (
            <LoginButton
              color="white"
              bg="#319795"
              width="100%"
              _hover={{ bg: '#2C7B7B' }}
              mt="8px"
            />
          )}
        </Flex>
      </Box>
      {comments.map((comment) => (
        <Box
          key={`comment-${comment.id}`}
          id={`comment-${comment.id}`}
          bg="#152036"
          border="1px #273343 solid"
          borderRadius="8px"
          boxShadow="0 0.5rem 1.2rem rgba(82, 85, 92, .15)"
          padding="5px"
          overflowX="auto"
          _hover={{ bg: '#1c2b49' }}
          onMouseOver={() =>
            comment.id !== undefined && handleMouseOver(comment.id)
          }
          onMouseLeave={() =>
            comment.id !== undefined && handleMouseLeave(comment.id)
          }
        >
          <Stack spacing="1">
            <Flex gap="2">
              <Box>
                <Avatar
                  size={'xs'}
                  src="https://pbs.twimg.com/profile_images/1651688795429822464/cmDeEncE_400x400.jpg"
                />
              </Box>
              <Box>
                <Badge
                  colorScheme="purple"
                  bg={getAddressColor(comment.commenterAddress)}
                  color="black"
                  px="2"
                  py="1"
                  borderRadius="full"
                  border="1px solid #4A5568"
                >
                  {shortenAddr(comment.commenterAddress)}
                </Badge>
              </Box>

              <Box>
                <Text as="span" fontSize="xs" color="#EAEBEF">
                  {Boolean(comment.commentedAt) &&
                    dayjs(comment.commentedAt).format('MM/DD/YYYY HH:mm')}
                </Text>
              </Box>
              <Box>
                <Text as="span" fontSize="xs" color="#EAEBEF">
                  - #{comment.id}
                </Text>
              </Box>
              <Box>
                <Button
                  backgroundColor={'#152036'}
                  _hover={{ bg: '#152036' }}
                  color={'#EAEBEF'}
                  size="xs"
                  onClick={() =>
                    comment.id !== undefined && handleReplyClick(comment.id)
                  }
                >
                  Reply
                </Button>
              </Box>
            </Flex>
            <Flex
              justifyContent=""
              alignItems="center"
              gap={2}
              fontWeight={'900'}
              fontSize={'xs'}
            >
              {renderCommentText(comment.text)}
            </Flex>
          </Stack>
        </Box>
      ))}
    </div>
  );
}

export default observer(Comments);
