import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Flex,
  Spinner,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import { Transaction, getTransactions } from '../../../../api/PoolApi';
import { Store } from '../../../../stores';
import TransferRow from './TransferRow';

type Props = {
  poolAddress: string;
  tokenSymbol: string;
  moneySymbol: string;
  setUsdPrice: (val: string) => void;
};

const TransactionsTable = ({
  poolAddress,
  tokenSymbol,
  moneySymbol,
  setUsdPrice,
}: Props) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageNumber, setMaxPageNumber] = useState(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [makerFilter, setMakerFilter] = useState<string | null>(null);
  const { chain, socket } = Store;
  const observer = useRef<IntersectionObserver | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const getColor = useCallback((type: string): string => {
    return type === 'buy' ? '#16C670' : '#EA3942';
  }, []);

  const getPageTransactions = async (page: number, maker: string | null) => {
    if (loading) {
      return;
    }
    setLoading(true);

    const lastTx = transactions[transactions.length - 1];
    if (lastTx && page > maxPageNumber) {
      return;
    }
    try {
      const pageResponse = await getTransactions(
        poolAddress,
        page,
        lastTx?.date || '',
        lastTx?.position || -1,
        maker || '0x'
      );
      if (pageResponse) {
        setTransactions((prev) => [
          ...(pageNumber > 0 ? prev : []),
          ...pageResponse.swaps,
        ]);
        setMaxPageNumber(parseInt(String(pageResponse.numberOfPages)));
      }
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setPageNumber(0);
  }, [poolAddress]);

  useEffect(() => {
    getPageTransactions(pageNumber, makerFilter);
  }, [poolAddress, pageNumber, makerFilter]);

  useEffect(() => {
    Store.connectToSocket();
    if (socket) {
      socket.onmessage = function (event) {
        const { data: dataStr } = event;
        const data = JSON.parse(dataStr);
        if (data.type === 'transaction') {
          const { data: tx } = data;
          if (data.poolAddress !== poolAddress) {
            return;
          }
          setTransactions((prev) => [tx, ...prev]);
          setUsdPrice(tx.priceUsd);
        }
      };
    }
  }, [socket, socket?.readyState, poolAddress]);

  const lastTransactionRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && pageNumber < maxPageNumber - 1) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [maxPageNumber, pageNumber]
  );

  const handleFilterClick = (maker: string) => {
    setMakerFilter(maker);
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth > 768); // Adjust this breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    setMakerFilter(null);
  }, [poolAddress]);

  return (
    <>
      <TableContainer
        ref={tableContainerRef}
        bg="#152036"
        borderRadius="8px"
        boxShadow="0 2px 4px rgba(0, 0, 0, 0.08)"
        w="100%"
        maxH={isMobile ? '500px' : ''}
        h="100%"
        overflowY="auto"
      >
        <Table overflowX="auto" size="sm" variant="simple" colorScheme="dark">
          <Thead position="sticky" top={0} bg="#152036" zIndex={1}>
            <Tr>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                Date
              </Th>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                Price
              </Th>
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                USD
              </Th>
              {isMobile && (
                <Th
                  borderColor="#273344"
                  css={{ color: '#FFFFFF' }}
                  fontWeight="bold"
                >
                  {tokenSymbol}
                </Th>
              )}
              {isMobile && (
                <Th
                  borderColor="#273344"
                  css={{ color: '#FFFFFF' }}
                  fontWeight="bold"
                >
                  {moneySymbol}
                </Th>
              )}
              <Th
                borderColor="#273344"
                css={{ color: '#FFFFFF' }}
                fontWeight="bold"
              >
                <Flex alignItems={'center'}>
                  <Box>Maker</Box>
                  <Box>
                    {makerFilter && (
                      <IconButton
                        aria-label="Filter by maker"
                        icon={<FiX />}
                        size="xs"
                        ml={2}
                        onClick={() => setMakerFilter(null)}
                        variant="ghost"
                        color="gray.400"
                        _hover={{ color: 'white' }}
                      />
                    )}
                  </Box>
                </Flex>
              </Th>
              {isMobile && (
                <Th
                  borderColor="#273344"
                  css={{ color: '#FFFFFF' }}
                  fontWeight="bold"
                ></Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {transactions
              .filter((transaction) =>
                makerFilter ? transaction.maker === makerFilter : true
              )
              .map((transaction, i) => (
                <Tr
                  key={`tx-${i}-${transaction.etherscanLink}`}
                  ref={
                    i === transactions.length - 1 ? lastTransactionRef : null
                  }
                  _hover={{ bg: '#1c2b49' }}
                >
                  <TransferRow
                    transaction={transaction}
                    isMobile={isMobile}
                    explorer={chain.explorer}
                    rowColor={getColor(transaction.type)}
                    onFilterClick={handleFilterClick}
                  />
                </Tr>
              ))}
          </Tbody>
        </Table>
        {loading && (
          <Flex justifyContent="center" alignItems="center" mt={4}>
            <Spinner size="lg" color="white" />
          </Flex>
        )}
      </TableContainer>
    </>
  );
};

export default TransactionsTable;
