import axios from 'axios';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

const lastBarsCache = new Map();

const apiAddress = process.env.REACT_APP_API_URL;
const configurationData = {
  supports_search: false,
  supports_group_request: false,
  supports_marks: true,
  supports_timescale_marks: true,
  interval: '1',
  supports_time: true,
  exchanges: [
    { value: '', name: 'All Exchanges', desc: '' },
    { value: 'NasdaqNM', name: 'NasdaqNM', desc: 'NasdaqNM' },
    { value: 'NYSE', name: 'NYSE', desc: 'NYSE' },
  ],
  symbols_types: [{ name: 'Crypto', value: 'crypto' }],
  supported_resolutions: ['1', '15', '1h', 'D', '2D', 'W', 'M'],
};

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    const newSymbols = [
      // {
      //  symbol: '<short symbol name>',
      //  full_name: '<full symbol name>', // e.g. BTCE:BTCUSD
      //  description: '<symbol description>',
      //  exchange: '<symbol exchange name>',
      //  ticker: '<symbol ticker name>',
      //  type: 'stock', // "futures"/"crypto"/"forex"/"index"
      // },
    ];
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    try {
      const res = await axios.get(
        `${apiAddress}/api/graph/symbol_info/${symbolName}`
      );
      const symbolInfo = res.data;
      onSymbolResolvedCallback(symbolInfo);
    } catch (err) {
      const testData = {
        ticker: 'Test',
        name: 'Test',
        description: 'BTC/USD',
        address: '0x20200120',
        type: 'crpyo',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: 'Dex',
        minmov: '0.000001',
        pricescale: '100000000',
        visible_plots_set: 'ohlcv',
        has_intraday: true,
        has_weekly_and_monthly: true,
        supported_resolutions: ['1', '15', '30', 'D', '2D'],
        volume_precision: 2,
        data_status: 'endofday',
      };
      onSymbolResolvedCallback(testData);
      // onResolveErrorCallback(err.message);
    }
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    // setTimeout(async () => {
    try {
      const data = await axios.get(
        `${apiAddress}/api/graph/bar/${symbolInfo.address}/${resolution}?from=${periodParams.from}&to=${periodParams.to}&count_back=${periodParams.countBack}`
      );
      let noData = data.data.noData;
      if (noData) {
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }
      let apiBars = data.data.bars;
      apiBars = apiBars.map((b) => ({
        ...b,
        high: parseFloat(b.high),
        low: parseFloat(b.low),
        open: parseFloat(b.open),
        close: parseFloat(b.close),
      }));
      const channelString = getChannelString(symbolInfo);
      const lastBar = lastBarsCache.get(channelString);
      const lB = apiBars.length;
      if (!lastBar && lB > 0) {
        lastBarsCache.set(channelString, {
          ...apiBars[apiBars.length - 1],
        });
      }

      onHistoryCallback(apiBars);
    } catch (e) {
      console.log('error', e);
    }
    // console.log(apiBars);

    // onHistoryCallback([], {
    // });
    // }, 1000);
  },

  subscribeBars(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(getChannelString(symbolInfo))
    );
  },

  unsubscribeBars(subscriberUID) {
    unsubscribeFromStream(subscriberUID);
  },

  getMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    // TODO: marks after connecting
  },

  getTimescaleMarks(
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) {
    // optional
    // TODO: marks after connecting
  },
};

function getChannelString(symbolInfo) {
  return `0~${symbolInfo.address}`;
}
