import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useDisconnect,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from '@web3modal/ethers/react';
import { BrowserProvider } from 'ethers';

import { createSiweMessage, signInWithEIP } from '../api/SessionApi';
import { Store } from '../stores';
import { updateAuthHeader } from '../api/BaseApi';

const projectId = '5b72f175205222cc4b57c8cd7082f982';

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
};

const metadata = {
  name: 'DexMap',
  description: 'DexMap',
  url: 'https://www.dexmap.app', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const ethersConfig = defaultConfig({
  metadata,
  rpcUrl: 'https://cloudflare-eth.com', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
  auth: {
    email: false,
  },
});

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
});

type Props = {
  variant?: string;
  colorScheme?: string;
  size?: string;
  mr?: number;
  color?: string;
  bg?: string;
  width?: string;
  mt?: string;
  _hover?: {
    bg: string;
  };
};

export function LoginButton(props: Props) {
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();

  async function sign() {
    if (!walletProvider) {
      alert('Please connect first');
      return;
    }

    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const message = createSiweMessage(
      signer.address,
      'This does not create any ethereum transaction. You are about to sign into DexMap and you will be able to most of the platform'
    );
    let signature;
    try {
      signature = await signer.signMessage(message);
    } catch {
      return;
    }

    await signInWithEIP({ message, signature, address });
    const userValExist = !!Cookies.get('userVal');
    if (userValExist) {
      Store.setUserState('logged_in');
      updateAuthHeader(); // Update the Authorization header in BaseApi
    }
  }

  function disconnectUser() {
    Cookies.remove('userVal');
    Store.setUserState('connect_wallet');
    return disconnect();
  }

  function getActionForUser() {
    if (Store.userState === 'connect_wallet') {
      return open();
    }
    if (Store.userState === 'sign_in') {
      return sign();
    }
    return disconnectUser();
  }

  function setInitialUserState() {
    if (!isConnected) {
      return Store.setUserState('connect_wallet');
    }

    const userValExist = !!Cookies.get('userVal');
    if (!userValExist) {
      return Store.setUserState('sign_in');
    }

    Store.setUserState('logged_in');
  }

  function decideUserStatus(): string {
    if (Store.userState === 'connect_wallet') {
      return 'Connect wallet';
    }
    if (Store.userState === 'sign_in') {
      return 'Sign In';
    }
    return 'Disconnect';
  }

  useEffect(() => {
    setInitialUserState();
  }, [isConnected, Cookies.get('userVal')]);

  return (
    <Button
      onClick={getActionForUser}
      variant={props.variant}
      colorScheme={props.colorScheme}
      size={props.size}
      mr={props.mr}
      color={props.color}
      bg={props.bg}
      width={props.width}
      _hover={props._hover}
      mt={props.mt}
    >
      {decideUserStatus()}
    </Button>
  );
}

export default observer(LoginButton);
