import React, { useState, useEffect } from 'react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from '@web3modal/ethers/react';
import { BrowserProvider, ethers } from 'ethers';

import { formatUSD } from '../../../../helpers/MoneyHelpers';

const projectId = '5b72f175205222cc4b57c8cd7082f982';

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
};

const metadata = {
  name: 'DexMap',
  description: 'AppKit Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
  rpcUrl: 'https://cloudflare-eth.com', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
});

const abi = [
  'function balanceOf(address) public view returns (uint256)',
  'function decimals() public view returns (uint8)',
];

type Props = {
  contractAddress: string;
  usdPrice: string;
  balanceWording?: string;
};

export default function TokenBalance({
  contractAddress,
  usdPrice,
  balanceWording = 'Balance',
}: Props) {
  const [userBalance, setUserBalance] = useState(0.0);
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();

  async function getUserAmount() {
    if (!walletProvider || !address) {
      return;
    }

    const ethersProvider = new BrowserProvider(walletProvider);
    const contractNode = new ethers.Contract(
      contractAddress,
      abi,
      ethersProvider
    );
    const balance = await contractNode.balanceOf(address);
    const decimals = await contractNode.decimals();
    const actualBalance = parseInt(balance) * 10 ** (18 - parseInt(decimals));
    setUserBalance(actualBalance / 10 ** 18);
  }

  function USDBalance() {
    return formatUSD(String(userBalance * parseFloat(usdPrice)));
  }

  useEffect(() => {
    getUserAmount();

    const intervalId = setInterval(() => {
      getUserAmount();
    }, 6000);

    return () => clearInterval(intervalId);
  }, [address, contractAddress, walletProvider]);
  return (
    <>
      <span>{balanceWording}:</span>{' '}
      <span>{address ? USDBalance() : 'Login to see'}</span>
    </>
  );
}
