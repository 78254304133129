import { observable, action, makeObservable } from 'mobx';

import { FAV_LIST_KEY } from './types/FavoriteToken';
import { Chain, getChain } from '../api/ChainApi';
import { Pool, getFavoritePools } from '../api/PoolApi';

class FavoriteStore {
  favorites: Pool[] = [];
  pageTitle: string = 'DexMap Main Page';
  chain: Chain = { fee: '0', symbol: 'eth', explorer: '', price: '0' };
  userState: string = 'connect_wallet';
  socket: WebSocket | null = null;

  constructor() {
    makeObservable(this, {
      socket: observable,
      favorites: observable,
      chain: observable,
      userState: observable,
      pageTitle: observable,
      setPageTitle: action,
      setUserState: action,
      addFavorite: action,
      setChain: action,
      loadChain: action,
      syncFavorites: action,
      removeFavorite: action,
      connectToSocket: action,
    });
  }

  async loadChain(symbol: string) {
    const chainApi = await getChain(symbol);
    this.setChain(chainApi);
  }

  async syncFavorites() {
    try {
      const poolAddresses = this.favorites.map((pool) => pool.poolAddress);
      const updatedFavorites = await getFavoritePools(poolAddresses);
      if (updatedFavorites.length) {
        this.setFavorites(updatedFavorites);
      }

      localStorage.setItem(FAV_LIST_KEY, JSON.stringify(updatedFavorites));
    } catch (error) {
      console.error('Error syncing favorites:', error);
    }
  }

  addFavorite(pool: Pool) {
    this.favorites.push(pool);
    localStorage.setItem(FAV_LIST_KEY, JSON.stringify(this.favorites));
  }

  removeFavorite(address: string) {
    this.favorites = this.favorites.filter(
      (token) => token.poolAddress !== address
    );
    localStorage.setItem(FAV_LIST_KEY, JSON.stringify(this.favorites));
  }

  setFavorites(favs: Pool[]) {
    this.favorites = favs || [];
  }

  isFavorited(address: string): boolean {
    return (
      (this.favorites || []).findIndex(
        (token) => token.poolAddress === address
      ) > -1
    );
  }

  setUserState(userState: string) {
    this.userState = userState;
  }

  setPageTitle(title: string) {
    this.pageTitle = title;
  }

  setChain(newChain: Chain) {
    this.chain = newChain;
  }

  connectToSocket() {
    if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
      return;
    }
    this.socket = new WebSocket(process.env.REACT_APP_WS_ENDPOINT || '');

    const r = this.connectToSocket;
    this.socket.onclose = function (e) {
      console.log(
        'Socket is closed. Reconnect will be attempted in 1 second.',
        e.reason
      );
      setTimeout(function () {
        r();
      }, 1000);
    };
  }
}

export const Store = new FavoriteStore();
