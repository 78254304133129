import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL = process.env.REACT_APP_API_URL;

export const axiosAPI = axios.create({
  baseURL,
  timeout: 3000,
});

export const updateAuthHeader = () => {
  axiosAPI.defaults.headers.common['Authorization'] = Cookies.get('userVal');
};

// Initial setup
updateAuthHeader();
