import React from 'react';
import { Box, Text, Stack, Flex, Divider, useBoolean } from '@chakra-ui/react';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

type Props = {
  title: React.ReactNode | JSX.Element;
  collapsableChildren: React.ReactNode;
  uncollapsableChildren?: React.ReactNode;
};

const Card = ({ title, collapsableChildren, uncollapsableChildren }: Props) => {
  const [flag, setFlag] = useBoolean();

  return (
    <Box
      bg="#152036"
      border="1px #273343 solid"
      borderRadius="15px"
      boxShadow="0 0.5rem 1.2rem rgba(82, 85, 92, .15)"
      padding="24px"
      overflowX="auto"
      position={'relative'}
    >
      <Stack spacing="1">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="lg" fontWeight="bold" color="#FFFF">
            <Flex justifyContent="" alignItems="center" gap={2}>
              {title}
            </Flex>
          </Text>
          <Text cursor="pointer" onClick={setFlag.toggle}>
            {flag ? (
              <IconChevronDown size="24px" color="#B5CDDB" />
            ) : (
              <IconChevronUp size="24px" color="#B5CDDB" />
            )}
          </Text>
        </Flex>
        {Boolean(uncollapsableChildren) && <Divider borderColor="#374046" />}
        {uncollapsableChildren}
        <Divider borderColor="#374046" />
        {!flag && collapsableChildren}
      </Stack>
    </Box>
  );
};
export default Card;
