import React, { FC } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';

interface AuditProps {
  isOpen: boolean;
  onClose: () => void;
  size: string;
  children: React.ReactNode;
  title?: string;
}

const Audit: FC<AuditProps> = ({ isOpen, onClose, size, children, title }) => {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={size}>
      <DrawerOverlay />
      <DrawerContent
        style={{ background: '#152136', color: '#EAEBEF' }}
        fontWeight={'semibold'}
      >
        <DrawerCloseButton />
        {title && <DrawerHeader>{title}</DrawerHeader>}
        <DrawerBody backdropBlur={'12px'}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Audit;
// audit no risk : #0BCB82
// audit some risk: #FFB534
// audit Danger :#EF5350
