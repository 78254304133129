import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Flex,
  Box,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { Pool, getPools } from '../api/PoolApi';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Trend from './Trend';
import trendLogo from '../assets/TrendLogo.svg';

type Props = {
  showTrend?: boolean;
  children: React.ReactNode;
};

function TokenLayout({ children, showTrend = false }: Props) {
  const navigate = useNavigate();
  const [trending, setTrending] = useState<Pool[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const loadTrading = async () => {
    const trendingFromAPI = await getPools('trending');
    trendingFromAPI && setTrending(trendingFromAPI.slice(0, 10));
  };

  useEffect(() => {
    loadTrading();
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <>
      <Flex
        minH={isMobile ? '' : '100vh'}
        bg="#232f44"
        direction="column"
        width="100%"
      >
        <Navbar />
        {showTrend && (
          <>
            <Flex bg="#152036" marginTop="0.5px">
              <Button
                onClick={onOpen}
                bg="#319795"
                color="white"
                borderRadius="xl"
                padding="0 10px"
              >
                <Box display="flex" alignItems="center">
                  <Box display="flex">
                    <Avatar size="2xs" src={trendLogo} />
                  </Box>
                  <Box>
                    <Text as="span" fontWeight="extrabold" color="white">
                      Trending
                    </Text>
                  </Box>
                </Box>
              </Button>
              <Marquee pauseOnHover>
                {trending.map((token, index) => (
                  <Box
                    cursor="pointer"
                    onClick={() => navigate(`/eth/pool/${token.poolAddress}`)}
                    marginTop="3px"
                    marginBottom="3px"
                    marginRight="20px"
                    key={`token-layout-${token.poolAddress}-${index}`}
                  >
                    <Text
                      as="span"
                      fontSize="sm"
                      fontWeight="extrabold"
                      color="#EAEBEF"
                    >
                      #{index + 1}-{' '}
                    </Text>
                    <Avatar
                      size="xs"
                      name={token.tokenName}
                      src={token.tokenLogo}
                      marginRight="2px"
                    />
                    <Text
                      as="span"
                      fontSize="sm"
                      fontWeight="semibold"
                      color="#EAEBEF"
                    >
                      {token.tokenName}
                    </Text>
                  </Box>
                ))}
              </Marquee>
            </Flex>
            <Trend trending={trending} isOpen={isOpen} onClose={onClose} />
          </>
        )}
        {children}
        <Footer />
      </Flex>
    </>
  );
}

export default observer(TokenLayout);
